import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Box,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import {
  Height,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import AfficherImagePackage from "../Popup/AfficherImagePackage";
import ButtonSonRadius from "../Button/buttonSonRadius";
import DownloadIcon from "@mui/icons-material/Download";
import axios from "axios";
import { serveurUrl } from "../../utils/Api";
import TitreGraphik from "../Typography/TitreGraphik";
import UpdateStatusBooking from "../../components/Popup/updateStatusBooking";
import {
  GetBookingData,
  updateOnBoardAction,
  updatePayedAction,
  updatelivredAction,
} from "../../store/slice/bookingSlice";
import { useDispatch, useSelector } from "react-redux";
import ActionBookinStatus from "./ActionBookingStatus";
import { etatInisialBookingSlice } from "../../store/slice/bookingSlice";
import CustomAlert from "../../components/Alert/Alert";

export async function handleDownload(url) {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const link = document.createElement("a");
    const objectURL = URL.createObjectURL(response.data);
    link.href = objectURL;
    link.download = `image`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(objectURL);
  } catch (error) {
    console.error("Error downloading image:", error);
  }
}

function ListPackageAndProduct({ bookingData, id }) {
  const [open, setOpen] = useState({});
  const [openPopup, setOpenPopup] = useState(false);
  const [image, setImage] = useState();
  const [popupOnboard, setPopupOnboard] = React.useState(false);
  const [popupPaye, setPaye] = React.useState(false);
  const [popupLivre, setlivre] = React.useState(false);
  const dispatch = useDispatch();
  const { etat, message } = useSelector((state) => state.BookingSlice);

  const handleRowClick = (packageId) => {
    setOpen((prevOpen) => ({
      ...prevOpen,
      [packageId]: !prevOpen[packageId],
    }));
  };

  function GetData() {
    dispatch(GetBookingData(id));
  }
  const getColor = (status) => {
    switch (status) {
      case "yes":
        return "green";
      case "no":
        return "red";
      default:
        return "orange";
    }
  };
  console.log(bookingData);
  const shipper = bookingData?.customer?.find(
    (customer) => customer.pivot.type === "Shipper"
  );
  const receiver = bookingData?.customer?.find(
    (customer) => customer.pivot.type === "Receiver"
  );
  return (
    <Box>
      <TableContainer
        component={Paper}
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableCell />

              <StyledTableCell>Package ID</StyledTableCell>
              <StyledTableCell>Price</StyledTableCell>
              <StyledTableCell>stockage</StyledTableCell>
              <StyledTableCell>Image</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingData?.package?.map((pkg) => (
              <React.Fragment key={pkg.id}>
                <StyledTableRow>
                  <StyledTableCell>
                    <IconButton
                      size="small"
                      onClick={() => handleRowClick(pkg.id)}
                    >
                      {open[pkg.id] ? (
                        <KeyboardArrowUp />
                      ) : (
                        <KeyboardArrowDown />
                      )}
                    </IconButton>
                  </StyledTableCell>

                  <StyledTableCell> {pkg.id}</StyledTableCell>
                  <StyledTableCell>{pkg.prixPackages}</StyledTableCell>
                  <StyledTableCell>{`${pkg.stoke_in.type__stoke.type} - N${pkg.stoke_in.id}`}</StyledTableCell>

                  <StyledTableCell>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={`${serveurUrl}/public/${pkg.Urlimage}`}
                        alt={`Package ${pkg.id}`}
                        width="130"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setImage(`${serveurUrl}/public/${pkg.Urlimage}`);
                          setOpenPopup(true);
                        }}
                      />
                      <ButtonSonRadius
                        variant="contained"
                        startIcon={<DownloadIcon />} // Added the download icon
                        onClick={() => {
                          handleDownload(
                            `${serveurUrl}/api/package/${pkg.Urlimage}`
                          );
                        }}
                      >
                        Download
                      </ButtonSonRadius>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                  >
                    <Collapse in={open[pkg.id]} timeout="auto" unmountOnExit>
                      <Box margin={1}>
                        <Table size="small" aria-label="products">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell align="center">
                                Category
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                product
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Quantity
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Price
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                Price Total
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pkg.products.map((product) => (
                              <StyledTableRow key={product.id}>
                                <StyledTableCell align="center">
                                  {product.category.nameCategory}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {product.nameProduct}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {product.pivot.NumberProduct}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {product.prixProduct}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {product.prixProduct *
                                    product.pivot.NumberProduct}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          <br />
          <br />
          <TitreGraphik>Booking Details</TitreGraphik>
          <br />
          <br />
          <Paper sx={{ padding: 2, maxWidth: 600, margin: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Shipper:</b>
                </LabelTypography>
                <LabelTypography>{shipper?.NameCustomer}</LabelTypography>
                <LabelTypography>{shipper?.NumberPhone}</LabelTypography>
                <LabelTypography>{shipper?.Email}</LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Receiver:</b>
                </LabelTypography>
                <LabelTypography>{receiver?.NameCustomer}</LabelTypography>
                <LabelTypography>{receiver?.NumberPhone}</LabelTypography>
                <LabelTypography>{receiver?.Email}</LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <LabelTypography variant="h6" gutterBottom>
                  <b>Number of Packages :</b> {bookingData?.QtyPackage}
                </LabelTypography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {bookingData?.package?.map((pkg) => (
                <>
                  <Grid item xs={12}>
                    <LabelTypography
                      sx={{
                        fontSize: "1.5rem",
                      }}
                    >
                      <b>Package {pkg.id}</b>
                    </LabelTypography>
                    {pkg?.products?.map((p) => (
                      <LabelTypography variant="h6" gutterBottom>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <LabelTypography variant="h6" gutterBottom>
                            x{" "}
                            <LabelTypography
                              variant="span"
                              sx={{ color: "#0671B5" }}
                            >
                              {p.pivot.NumberProduct}
                            </LabelTypography>{" "}
                            {p.nameProduct}{" "}
                          </LabelTypography>
                          <LabelTypography variant="h6" gutterBottom>
                            {p.pivot.NumberProduct * p.prixProduct} VUV
                          </LabelTypography>
                        </Box>
                      </LabelTypography>
                    ))}
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <LabelTypography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <LabelTypography>Total :</LabelTypography>
                        <LabelTypography sx={{ color: "#061328" }}>
                          <b>{pkg.prixPackages} vuv</b>
                        </LabelTypography>
                      </Box>
                    </LabelTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <ButtonSonRadius
                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      fullWidth
                      style={{
                        borderColor: "#002855",
                        color: "#002855",
                        height: "8vh",
                      }}
                      sx={{ marginBottom: 2 }}
                      onClick={() => {
                        handleDownload(
                          `${serveurUrl}/api/package/${pkg.Urlimage}`
                        );
                      }}
                    >
                      Download delivery docket
                    </ButtonSonRadius>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                </>
              ))}
            </Grid>
            <ActionBookinStatus
              b={bookingData}
              setPaye={setPaye}
              setlivre={setlivre}
              setPopupOnboard={setPopupOnboard}
              setId={() => {}}
            />
          </Paper>
        </Box>
      </Box>
      <UpdateStatusBooking
        open={popupOnboard}
        onclose={() => {
          setPopupOnboard(false);
        }}
        title="update on board"
        onsend={(data) => {
          dispatch(updateOnBoardAction(data)).then((result) => {
            if (updateOnBoardAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPopupOnboard(false);
        }}
        id={id}
      />
      <UpdateStatusBooking
        open={popupPaye}
        onclose={() => {
          setPaye(false);
        }}
        title="update Payé"
        onsend={(data) => {
          dispatch(updatePayedAction(data)).then((result) => {
            if (updatePayedAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPaye(false);
        }}
        id={id}
      />
      <UpdateStatusBooking
        open={popupLivre}
        onclose={() => {
          setlivre(false);
        }}
        title="update Livré"
        onsend={(data) => {
          dispatch(updatelivredAction(data)).then((result) => {
            if (updatelivredAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setlivre(false);
        }}
        id={id}
      />
      <AfficherImagePackage
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
        }}
        image={image}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}

export default ListPackageAndProduct;
