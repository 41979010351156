import Package from "../../components/Package/Package";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useCallback, useState } from "react";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import {
  GetStokeType,
  NewBookingAction,
  etatInisialBookingSlice,
} from "../../store/slice/bookingSlice";
import CustomAlert from "../../components/Alert/Alert";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import NewFastBookingFormCostumer from "../../components/CostmureForm/formNewFastBooking";
import { Getstoke } from "../../store/slice/bookingSlice";
import LoadingPage from "../../components/loading";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import { useNavigate } from "react-router-dom";
import FormAddNewFastBooking from "../../components/port/FormAddNewBooking";
import { AddStokeInAction } from "../../store/slice/bookingSlice";
import AddStokeIn from "../../components/Popup/AddStokeIn";
import { getPorts, getVoyagePortCaptain } from "../../store/slice/PortsSlice";

const PageNewFastBookingcapitan = () => {
  return <Indexdasboard Children={<NewFastBooking />} />;
};

function NewFastBooking() {
  const [packages, setPackages] = useState([
    {
      produits: [],
      file: null,
    },
  ]);
  const [errors, setErrors] = useState({});
  const [IndexsFileNull, setIndexFileNull] = useState([]);
  const [Prix, setPrix] = useState(0);
  const dispatch = useDispatch();
  const { etat, message, loading } = useSelector((state) => state.BookingSlice);
  const { user } = useSelector((state) => state.AuthSlice);
  const { ports } = useSelector((state) => state.PortsSlice);
  const Navigate = useNavigate();
  const [openAddStoke, setOpenAddStoke] = useState(false);
  useEffect(() => {
    if (ports.length === 0) {
      if (user.Role === "captain") {
        dispatch(getVoyagePortCaptain());
        console.log("test1");
      } else {
        console.log("test2");
        dispatch(getPorts());
      }
    }
  }, [dispatch, ports, user]);

  const handleRemove = (indexPack, indexProduct) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack].produits.splice(indexProduct, 1);
      return updatedPack;
    });
  };

  const UpdateQuantite = (indexPack, indexProduct, quantity) => {
    if (quantity > 0) {
      setPackages((prev) => {
        const updatedPack = [...prev];
        updatedPack[indexPack].produits[indexProduct] = {
          ...updatedPack[indexPack].produits[indexProduct],
          quantity: quantity,
        };
        return updatedPack;
      });
    }
  };

  const UpdateStoke = (indexPack, stoke) => {
    setPackages((prev) => {
      const updatedPack = [...prev];
      updatedPack[indexPack] = {
        ...updatedPack[indexPack],
        stoke: stoke,
      };
      return updatedPack;
    });
  };

  const AddProductsPackage = (index, products) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];
      const updatedProduits = [...updatedProducts[index].produits, ...products];
      updatedProducts[index] = {
        ...updatedProducts[index],
        produits: updatedProduits,
      };
      return updatedProducts;
    });
  };

  const AddfilePackage = (index, file) => {
    setPackages((prev) => {
      const updatedProducts = [...prev];

      updatedProducts[index] = {
        ...updatedProducts[index],
        file: file,
      };
      return updatedProducts;
    });
  };

  useEffect(() => {
    let totalPrice = 0;
    packages.forEach((item) => {
      const { produits } = item;
      produits.forEach((produit) => {
        console.log(produit.product.prixProduct);
        console.log(produit.quantity);
        totalPrice += produit.product.prixProduct * produit.quantity;
      });
    });
    setPrix(totalPrice);
    setIndexFileNull([]);
  }, [packages, setPrix]);

  const DispatchGetStokeIn = useCallback(() => {
    dispatch(Getstoke());
  }, [dispatch]);
  useEffect(() => {
    DispatchGetStokeIn();
  }, [DispatchGetStokeIn]);

  useEffect(() => {
    dispatch(GetStokeType());
  }, [dispatch]);
  const sendData = (e) => {
    e.preventDefault();
    setIndexFileNull([]);

    // Form customer
    const formData = new FormData(e.target);
    const shipperPhoneNumber = formData.get("ShipperPhoneNumber");
    const receiverPhoneNumber = formData.get("ReceiverPhoneNumber");
    const ReceiverEmail = formData.get("ReceiverEmail");
    const ShipperEmail = formData.get("ShipperEmail");
    const errors = {};

    if (!isValidPhoneNumber(shipperPhoneNumber)) {
      errors.ShipperPhoneNumber =
        "Shipper phone number must be exactly 10 digits.";
    }

    if (!isValidPhoneNumber(receiverPhoneNumber)) {
      errors.ReceiverPhoneNumber =
        "Receiver phone number must be exactly 10 digits.";
    }
    if (!isValidEmail(ReceiverEmail)) {
      errors.ReceiverEmail =
        "Invalid email address. Please provide a valid email.";
    }
    if (!isValidEmail(ShipperEmail)) {
      errors.ShipperEmail =
        "Invalid email address. Please provide a valid email.";
    }

    // Validate packages
    let stopProcess = false; // Initialiser le drapeau pour indiquer si le processus doit être arrêté

    packages.forEach((pack, index) => {
      if (!pack.file) {
        setIndexFileNull((prev) => [...prev, index]);
        stopProcess = true;
        return;
      }
    });

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      stopProcess = true;
    }

    if (stopProcess) {
      return;
    }

    if (IndexsFileNull.length > 0) {
      return;
    }
    //filter pack if n'exist pas un produit
    const dataPackFilter = packages.filter(
      (pack) => pack.produits.length > 0 && pack.file
    );
    formData.append("packages", JSON.stringify(dataPackFilter));
    dataPackFilter.forEach((pack, index) => {
      formData.append(`image${index}`, pack.file);
      formData.append(`prixPack${index}`, calculePrixPack(pack.produits));
    });
    formData.append("prixTotal", Prix);
    dispatch(NewBookingAction(formData)).then((result) => {
      if (NewBookingAction.fulfilled.match(result)) {
        Navigate(`/${user.Role}/dashboard`);
      }
    });
  };

  const calculePrixPack = (produits) => {
    let totalPrice = 0;
    produits.forEach((produit) => {
      console.log(produit.product.prixProduct);
      console.log(produit.quantity);
      totalPrice += produit.product.prixProduct * produit.quantity;
    });
    return totalPrice;
  };
  const isValidPhoneNumber = (phoneNumber) => {
    // Check if the phone number has exactly 10 digits
    return /^\d{10}$/.test(phoneNumber);
  };

  const isValidEmail = (email) => {
    // Regular expression pattern for email validation
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };
  const addPackage = () => {
    setPackages([
      ...packages,
      {
        produits: [],
        file: null,
      },
    ]);
  };

  const DispatchAddStokeIn = (data) => {
    dispatch(AddStokeInAction(data));
  };

  return (
    <Box>
      <TitreGraphik>⬅ New fast booking</TitreGraphik>
      <Box marginTop="2%">
        <form onSubmit={sendData}>
          <Box sx={{ background: "white", padding: { xs: "3%", md: "5%" } }}>
            <FormAddNewFastBooking />
            <NewFastBookingFormCostumer errors={errors} />
            {packages.map((pack, index) => (
              <Package
                key={index}
                pack={pack}
                packages={packages}
                index={index}
                AddProductsPackage={AddProductsPackage}
                AddfilePackage={AddfilePackage}
                errorFiles={IndexsFileNull}
                UpdateQuantite={UpdateQuantite}
                handleRemove={handleRemove}
                UpdateStoke={UpdateStoke}
                setOpenAddStoke={setOpenAddStoke}
              />
            ))}
            <Box marginLeft="5%" marginTop="7%">
              <ButtonSonRadius
                marginLeft="3%"
                variant="contained"
                sx={{
                  backgroundColor: "#064C91",
                  height: "8vh",
                  "&:hover": {
                    backgroundColor: "#0A3D6A", // Change background color on hover
                  },
                }}
                onClick={addPackage}
              >
                + Add a package
              </ButtonSonRadius>
            </Box>

            <hr
              style={{
                border: "none",
                height: "1px",
                backgroundColor: "gray",
                paddingLeft: "10%",
                paddingRight: "10%",
                marginTop: "4%",
                marginBottom: "3%",
              }}
            />
            <TitreGraphik>Price : {Prix} vuv</TitreGraphik>
          </Box>
          <ButtonSonRadius
            variant="contained"
            type="submit"
            sx={{
              height: "8vh",
              marginTop: "3%",
            }}
          >
            Confirm information provided
          </ButtonSonRadius>
        </form>
        <CustomAlert
          open={etat !== "inisial"}
          onClose={() => {
            dispatch(etatInisialBookingSlice());
          }}
          etat={etat}
          message={message}
        />
      </Box>
      <AddStokeIn
        open={openAddStoke}
        onClose={() => setOpenAddStoke(false)}
        Add={(data) => {
          setOpenAddStoke(false);
          DispatchAddStokeIn(data);
        }}
      />
      <LoadingPage open={loading} />
    </Box>
  );
}

export default PageNewFastBookingcapitan;
