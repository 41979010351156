import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "../pages/Auth/login";
//import Signup from "../pages/Auth/SignUp";
import PriviteRoute from "./PriviteRoute";
import { useSelector } from "react-redux";
import DataBookingPage from "../pages/all/DataBookingPage";

export default function IndexRouter() {
  const { IsAuth } = useSelector((state) => state.AuthSlice);
  const { user } = useSelector((state) => state.AuthSlice);
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            !IsAuth ? (
              <Login />
            ) : (
              <Navigate to={`/${user.Role}/dashboard`} replace />
            )
          }
        />
        {PriviteRoute.map((p) => (
          <Route
            path={p.path}
            element={
              IsAuth ? (
                user.Role === p.role ? (
                  p.element
                ) : (
                  <Navigate to="/unauthorized" replace />
                )
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        ))}
        <Route path="/bookingData/:id" element={<DataBookingPage />} />
      </Routes>
    </BrowserRouter>
  );
}
