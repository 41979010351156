import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import NumberGeneral from "../../components/Statistique/NumberGeneral";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ButtonSimple from "../../components/Button/ButtonSimple";
import VoyageList from "../../components/Voyage/VoyageList";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetVoyageDashboard } from "../../store/slice/VoyageSlice";
import LoadingPage from "../../components/loading";
import { styleInput } from "../../assets/style/Input";
import { GetBooingVoyageCustomer } from "../../store/slice/bookingSlice";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LabelTypography from "../../components/Typography/LabelTypography";
import ListBookingVoyageCosutmer from "../../components/Booking/ListBookingVoyageCosutmer";
import { Link } from "react-router-dom";

function DashboardCommercial() {
  return (
    <Box>
      <Indexdasboard Children={<PageDashboard />} />
    </Box>
  );
}
const head = [
  "N°Voyage",
  "Ship name",
  "Captain's name",
  "Route",
  "Date and time cutof",
  "Date & time departure",
  "Date and time Arrival",
  "Status",
];

const headBooking = [
  "N° booking",
  "Creation date",
  "Destination",
  "Customer",
  "N° Voyage",
  "date deparature",
  "date arrival",
  "Status",
];
function PageDashboard() {
  const voyages = useSelector((state) => state.VoyageSlice.voyageDashboard);
  const bookingData = useSelector((state) => state.BookingSlice.bookings);
  const [order, setOrder] = useState("desc");
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      sort: "desc",
      number: 5,
    };
    dispatch(GetVoyageDashboard(data));
  }, [dispatch]);

  useEffect(() => {
    const data = {
      order: order,
      number: 5,
    };
    dispatch(GetBooingVoyageCustomer(data));
  }, [dispatch, order]);
  return (
    <Box>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TitreGraphik>Dashboard</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <NumberGeneral />
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <TitreGraphik sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                voyage List
              </TitreGraphik>
              <Link to="/commercial/VoyageManagement">
                <ButtonSimple
                  sx={{ ...styleInput, color: "primary", padding: "10px" }}
                >
                  view more
                </ButtonSimple>
              </Link>
            </Box>
            <br />
            <VoyageList voyages={voyages} head={head} />
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Grid container>
              <Grid item xs={6}>
                <TitreGraphik sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                  Reservation list
                </TitreGraphik>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <Link to="/commercial/VoyageManagement">
                        <ButtonSimple
                          sx={{
                            ...styleInput,
                            color: "primary",
                            padding: "10px",
                            Height: "8vh",
                          }}
                        >
                          view more
                        </ButtonSimple>
                      </Link>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <LabelTypography id="sortList">sort</LabelTypography>
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sortlist"
                          id="sortList"
                          name="sortList"
                          sx={styleInput}
                          value={order}
                          onChange={(e) => setOrder(e.target.value)}
                        >
                          <MenuItem value={"desc"}>
                            From newest to oldest
                          </MenuItem>
                          <MenuItem value={"asc"}>
                            From oldest to newest
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <br />
            <ListBookingVoyageCosutmer
              head={headBooking}
              Bookings={bookingData}
            />
          </Card>
        </Grid>
      </Grid>
      <LoadingPage />
    </Box>
  );
}

export default DashboardCommercial;
