import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";
export const getVoyagePortCaptain = createAsyncThunk(
  "get last voyage captain and port",
  async () => {
    const response = await Api_Token.get("/voyage/getVoyageAndPort");
    return response.data;
  }
);

export const getPorts = createAsyncThunk("get ports", async () => {
  const response = await Api_Token.get("/port/getPorts");
  return response.data;
});

export const ActivePortsAction = createAsyncThunk(
  "active ports",
  async (id) => {
    const response = await Api_Token.post(`/port/activePort/${id}`);
    return response.data;
  }
);

const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  ports: [],
};
export const PortsSlice = createSlice({
  name: "ports slice",
  initialState,
  reducers: {
    etatInisialBookingSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVoyagePortCaptain.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVoyagePortCaptain.fulfilled, (state, action) => {
      state.loading = false;
      state.ports = action.payload?.ports;
    });
    builder.addCase(getVoyagePortCaptain.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getPorts.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPorts.fulfilled, (state, action) => {
      state.loading = false;
      state.ports = action.payload;
    });
    builder.addCase(getPorts.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default PortsSlice.reducer;
export const { etatInisialBookingSlice } = PortsSlice.actions;
