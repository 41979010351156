import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import editIcon from "../../assets/Images/editIcon.svg";
import { Box, Button, Divider, Grid, Card, CardContent } from "@mui/material";
import { Link } from "react-router-dom";
import LabelTypography from "../Typography/LabelTypography";
import ActionBookinStatus from "./ActionBookingStatus";

function ListBooking({
  booking,
  setId,
  setPaye,
  setPopupOnboard,
  setlivre,
  role,
  head,
}) {
  const getColor = (status) => {
    switch (status) {
      case "yes":
        return "green";
      case "no":
        return "red";
      default:
        return "orange";
    }
  };
  return (
    <Box>
      <TableContainer sx={{ display: { xs: "none", md: "flex" } }}>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              {head.map((h) => (
                <StyledTableCell align="center">{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {booking?.map((b) => {
              const shipper = b.customer.find(
                (c) => c.pivot.type === "Shipper"
              );
              // Find the customer of type 'Receiver'
              const receiver = b.customer.find(
                (c) => c.pivot.type === "Receiver"
              );

              const destination = b.port.find(
                (p) => p.pivot.typePort === "destination"
              );
              return (
                <StyledTableRow key={b.id}>
                  <StyledTableCell align="center">
                    <Link to={`/bookingData/${b.id}`}>{b.id}</Link>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {shipper ? shipper.NameCustomer : "No Shipper"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {receiver ? receiver.NameCustomer : "No Receiver"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {destination ? destination.nomPort : "No destination"}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {b.QtyPackage}
                  </StyledTableCell>
                  <StyledTableCell>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: getColor(b.OnBoard),
                      }}
                    >
                      {b.OnBoard}
                      <img
                        src={editIcon}
                        alt="Edit"
                        height="30"
                        style={{ marginLeft: "5px" }}
                        onClick={() => {
                          setId(b.id);
                          setPopupOnboard(true);
                        }}
                      />
                    </Box>
                  </StyledTableCell>
                  {role === "captain" && (
                    <>
                      <StyledTableCell align="center">
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: getColor(b.Payé),
                          }}
                        >
                          {b.Payé}
                          <img
                            src={editIcon}
                            alt="Edit"
                            height="30"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              setId(b.id);
                              setPaye(true);
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: getColor(b.Livré),
                          }}
                        >
                          {b.Livré}
                          <img
                            src={editIcon}
                            alt="Edit"
                            height="30"
                            style={{ marginLeft: "5px" }}
                            onClick={() => {
                              setId(b.id);
                              setlivre(true);
                            }}
                          />
                        </Box>
                      </StyledTableCell>
                    </>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box width="100%">
          {booking?.map((b) => {
            const shipper = b.customer.find((c) => c.pivot.type === "Shipper");
            // Find the customer of type 'Receiver'
            const receiver = b.customer.find(
              (c) => c.pivot.type === "Receiver"
            );

            const destination = b.port.find(
              (p) => p.pivot.typePort === "destination"
            );

            return (
              <Card
                variant="outlined"
                sx={{ maxWidth: 345, width: "95%", margin: "auto" }}
              >
                <CardContent>
                  <LabelTypography textAlign="center">
                    ID Booking:{" "}
                    <Link to={`/bookingData/${b.id}`}>
                      <LabelTypography component="span" color="#064C91">
                        #{b.id}
                      </LabelTypography>
                    </Link>
                  </LabelTypography>
                  <LabelTypography textAlign="center">
                    Destination:
                    <LabelTypography
                      component="span"
                      style={{ color: "#064C91" }}
                    >
                      {destination ? destination.nomPort : "No destination"}
                    </LabelTypography>
                  </LabelTypography>
                  <Divider sx={{ margin: "10px 0" }} />

                  <LabelTypography color="text.primary">
                    From :
                    <LabelTypography component="span" color="#0671B5">
                      {shipper ? shipper.NameCustomer : "No Shipper"}
                    </LabelTypography>
                  </LabelTypography>

                  <LabelTypography color="text.primary">
                    To :
                    <LabelTypography component="span" color="#0671B5">
                      {receiver ? receiver.NameCustomer : "No Receiver"}
                    </LabelTypography>
                  </LabelTypography>

                  <Divider sx={{ margin: "10px 0" }} />
                  <LabelTypography color="text.primary">
                    Number of Packages:{" "}
                    <LabelTypography component="span" color="#064C91">
                      {b.QtyPackage}
                    </LabelTypography>
                  </LabelTypography>
                  <Divider sx={{ margin: "10px 0" }} />
                  <ActionBookinStatus
                    setPopupOnboard={setPopupOnboard}
                    setId={setId}
                    setlivre={setlivre}
                    setPaye={setPaye}
                    b={b}
                  />
                </CardContent>
              </Card>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
}

export default ListBooking;
