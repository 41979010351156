import {
  Box,
  Card,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ButtonSimple from "../../components/Button/ButtonSimple";
import ExportButton from "../../components/Button/ButtonExport";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import { StyledTableCell } from "../../assets/style/Table";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import {
  GetCustomer,
  etatInisialCustomerSlice,
} from "../../store/slice/customerSlice";
import { useEffect, useState, useCallback } from "react";
import ButtonRadius from "../../components/Button/ButtonRadius";
import { Link } from "react-router-dom";
import EditCustomer from "../../components/Popup/EditCustomer";
import CustomAlert from "../../components/Alert/Alert";

export default function PageCustomerManagement() {
  return <Indexdasboard Children={<CustomerManagement />} />;
}

function CustomerManagement() {
  const { CustomerData, CustomerAllData, etat, message } = useSelector(
    (state) => state.CustomerSlice
  );
  const [Page, setPage] = useState(1);
  const [dataUpdate, setDataUpdate] = useState();
  const [openEdit, setOpenEdit] = useState(false);
  const dispatch = useDispatch();
  const HasMore = CustomerData.current_page < CustomerData.last_page;

  const fetchMoreData = useCallback(
    (Page) => {
      dispatch(GetCustomer(Page));
    },
    [dispatch]
  );

  useEffect(() => {
    fetchMoreData(Page);
  }, [fetchMoreData, Page]);

  console.log(CustomerAllData);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Customer Management</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "2%" }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Link to="/commercial/AddCustomer">
                  <ButtonSimple
                    variant="contained"
                    sx={{ background: "#064C91" }}
                  >
                    + Add a customer
                  </ButtonSimple>
                </Link>
              </Grid>
              <Grid item xs={6} sx={{ textAlign: "right" }}>
                <ExportButton />
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <InfiniteScroll
                    dataLength={CustomerAllData.length}
                    next={() => {
                      setPage(Page + 1);
                    }}
                    hasMore={HasMore}
                    loader={<h4>Loading...</h4>}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <StyledTableCell>Id Customer</StyledTableCell>
                          <StyledTableCell>Name</StyledTableCell>
                          <StyledTableCell>Company</StyledTableCell>
                          <StyledTableCell>Adress</StyledTableCell>
                          <StyledTableCell>Email</StyledTableCell>
                          <StyledTableCell>Phone number</StyledTableCell>
                          <StyledTableCell>Action</StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {CustomerAllData.map((c, index) => (
                          <TableRow key={index}>
                            <StyledTableCell>{c.id}</StyledTableCell>
                            <StyledTableCell>{c.NameCustomer}</StyledTableCell>
                            <StyledTableCell>
                              {c?.company?.nomCompany}
                            </StyledTableCell>
                            <StyledTableCell>{c?.adress}</StyledTableCell>
                            <StyledTableCell>{c.Email}</StyledTableCell>
                            <StyledTableCell>{c.NumberPhone}</StyledTableCell>
                            <StyledTableCell>
                              <ButtonRadius
                                onClick={() => {
                                  setDataUpdate(c);
                                  setOpenEdit(true);
                                }}
                                sx={{ background: "#064C91", color: "white" }}
                              >
                                Edit
                              </ButtonRadius>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </InfiniteScroll>
                </TableContainer>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      <EditCustomer
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
        data={dataUpdate}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialCustomerSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}
