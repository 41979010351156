import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import StatusLabel from "../status/status";
import ButtonRadius from "../Button/ButtonRadius";
import DateFormate from "../date/dateFormat";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import { Box, Card, CardContent, Grid } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";

export default function ListBookingVoyageCosutmer({
  head,
  Bookings,
  dateSimple,
  edit,
}) {
  const dateCreation = (dt) => {
    const date = new Date(dt);
    return `${date.getDay()}/${date.getMonth()}/${date.getFullYear()}`;
  };
  return (
    <Box>
      <TableContainer
        sx={{ maxWidth: "950px", display: { xs: "none", md: "flex" } }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {head.map((h) => (
                <StyledTableCell align="center">{h}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Bookings?.map((b) => {
              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );
              return (
                <StyledTableRow>
                  {head.includes("N° booking") && (
                    <Link to={`/bookingData/${b.id}`}>
                      <StyledTableCell align="center">{b.id}</StyledTableCell>
                    </Link>
                  )}
                  {head.includes("Creation date") && (
                    <StyledTableCell align="center">
                      {dateCreation(b.created_at)}
                    </StyledTableCell>
                  )}
                  {head.includes("Route") && (
                    <StyledTableCell align="center">test</StyledTableCell>
                  )}
                  {head.includes("Destination") && (
                    <StyledTableCell align="center">
                      {destination?.nomPort}
                    </StyledTableCell>
                  )}

                  {head.includes("Customer") && (
                    <StyledTableCell align="center">
                      {b.shipper[0].NameCustomer}
                    </StyledTableCell>
                  )}
                  {head.includes("N° Voyage") && (
                    <StyledTableCell align="center">
                      {b.voyage?.id}
                    </StyledTableCell>
                  )}

                  {head.includes("date deparature") &&
                    (b.voyage?.dateDeparture ? (
                      dateSimple ? (
                        <StyledTableCell align="center">
                          {format(new Date(b.voyage.dateDeparture), "dd MMM")}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          sx={{ minWidth: "150px" }}
                        >
                          <DateFormate date={b.voyage.dateDeparture} />
                        </StyledTableCell>
                      )
                    ) : (
                      <StyledTableCell></StyledTableCell>
                    ))}
                  {head.includes("date arrival") &&
                    (b.voyage?.dateArrival ? (
                      dateSimple ? (
                        <StyledTableCell align="center">
                          {format(new Date(b.voyage.dateArrival), "dd MMM")}
                        </StyledTableCell>
                      ) : (
                        <StyledTableCell
                          align="center"
                          sx={{ minWidth: "150px" }}
                        >
                          <DateFormate date={b.voyage.dateArrival} />
                        </StyledTableCell>
                      )
                    ) : (
                      <StyledTableCell></StyledTableCell>
                    ))}

                  {head.includes("Status") && (
                    <StyledTableCell align="center">
                      <StatusLabel status={b.status.etat} />
                    </StyledTableCell>
                  )}
                  {head.includes("Invoice amount") && (
                    <StyledTableCell align="center">
                      {b.price} $
                    </StyledTableCell>
                  )}
                  {head.includes("Bill of lading") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        sx={{ background: "#068991", color: "white" }}
                      >
                        Download
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                  {head.includes("Invoice") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        sx={{ background: "#A70000", color: "white" }}
                      >
                        Invoice
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                  {head.includes("Payment") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        sx={{ background: "black", color: "white" }}
                      >
                        Change value
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                  {head.includes("Action") && (
                    <StyledTableCell align="center">
                      <ButtonRadius
                        onClick={() => {
                          edit(b.id, b);
                        }}
                        sx={{ background: "#064C91", color: "white" }}
                      >
                        Edit
                      </ButtonRadius>
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: { xs: "flex ", md: "none" } }}>
        <Box sx={{ width: "95%", margin: "auto" }}>
          <Grid container spacing={2}>
            {Bookings?.map((b) => {
              const destination = b?.port?.find(
                (p) => p.pivot.typePort === "destination"
              );

              return (
                <Grid item xs={12}>
                  <Card
                    variant="outlined"
                    sx={{ maxWidth: 345, padding: "2%" }}
                  >
                    <CardContent>
                      {head.includes("N° booking") && (
                        <LabelTypography variant="h6" component="div">
                          N° Booking:{" "}
                          <Link href={`/bookingData/${b.id}`}>{b.id}</Link>
                        </LabelTypography>
                      )}
                      {head.includes("Destination") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Destination:
                          <LabelTypography component="span" color="#064C91">
                            {destination?.nomPort}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("Customer") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Customer:
                          <LabelTypography component="span" color="#064C91">
                            {b.shipper[0]?.NameCustomer}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("N° Voyage") && (
                        <LabelTypography variant="body1" color="text.primary">
                          N° Voyage:
                          <LabelTypography component="span" color="#064C91">
                            {b?.voyage?.id}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("date deparature") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Date departure:
                          <LabelTypography component="span" color="#064C91">
                            {b.voyage?.dateDeparture(
                              format(
                                new Date(b.voyage?.dateDeparture),
                                "dd MMM"
                              )
                            )}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("date arrival") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Date arrival:
                          <LabelTypography component="span" color="#064C91">
                            {b.voyage?.dateArrival &&
                              format(new Date(b.voyage?.dateArrival), "dd MMM")}
                          </LabelTypography>
                        </LabelTypography>
                      )}
                      {head.includes("Status") && (
                        <LabelTypography variant="body1" color="text.primary">
                          Status: <StatusLabel status={b.status.etat} />
                        </LabelTypography>
                      )}
                      {head.includes("Invoice amount") && (
                        <LabelTypography color="text.primary">
                          Invoice amount:
                          <LabelTypography component="span" color="#064C91">
                            {b?.price} $
                          </LabelTypography>
                        </LabelTypography>
                      )}
                    </CardContent>
                    {head.includes("Action") && (
                      <ButtonRadius
                        onClick={() => {
                          edit(b.id, b);
                        }}
                        sx={{
                          background: "#064C91",
                          color: "white",
                          width: "30%",
                          marginLeft: "5%",
                        }}
                      >
                        Edit
                      </ButtonRadius>
                    )}
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
