import VoyageManagement from "../../components/Voyage/VoyageManagement";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
const head = [
  "N°Voyage",
  "Ship name",
  "Captain's name",
  "Route",
  "Departure Port",
  "Layovers",
  "Arival Port",
  "Status",
  "Action",
];

function PageVoyageManagementCommercial() {
  return <Indexdasboard Children={<VoyageManagement head={head} />} />;
}

export default PageVoyageManagementCommercial;
