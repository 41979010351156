import { Card, Grid } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import TitreGraphik from "../Typography/TitreGraphik";
import GroupsIcon from "@mui/icons-material/Groups";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CaptainIcon from "../../assets/Images/captainIcon.svg";
import ButtonTransparent from "../Button/ButtonTransparent";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetStatistiqueGeneral } from "../../store/slice/StatistiqueSlice";

const CardDataButton = ({ data }) => {
  return (
    <Card sx={{ padding: "8%", borderRadius: "5%" }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <LabelTypography sx={{ color: "gray", fontSize: "13px" }}>
                {data.titre}
              </LabelTypography>
            </Grid>
            <Grid item xs={12}>
              <TitreGraphik>{data.Number}</TitreGraphik>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {data.icon}
        </Grid>
        {data?.textButton && (
          <Grid item xs={12} textAlign="center">
            <Link to={data.LinkButton}>
              <ButtonTransparent fullWidth>{data.textButton}</ButtonTransparent>
            </Link>
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          <Link to={data.linkSousText} style={{ textDecoration: "none" }}>
            <LabelTypography
              sx={{
                fontSize: "15px",
                color: "#064C91",
                textDecoration: "underline",
              }}
            >
              {data.soustext}
            </LabelTypography>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

const CardDataSimple = ({ data }) => {
  return (
    <Card sx={{ padding: "8%", borderRadius: "5%", height: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center"></Grid>
        <Grid item xs={6}>
          <Grid container>
            <Grid item xs={12}>
              <LabelTypography sx={{ color: "gray", fontSize: "13px" }}>
                {data.titre}
              </LabelTypography>
            </Grid>
            <Grid item xs={12}>
              <TitreGraphik>{data.Number}</TitreGraphik>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
          }}
        >
          {data.icon}
        </Grid>
        <Grid item xs={12}></Grid>

        <Grid item xs={12} textAlign="center">
          <Link to={data.linkSousText} style={{ textDecoration: "none" }}>
            <LabelTypography
              sx={{
                fontSize: "15px",
                color: "#064C91",
                textDecoration: "underline",
              }}
            >
              {data.soustext}
            </LabelTypography>
          </Link>
        </Grid>
      </Grid>
    </Card>
  );
};

function NumberGeneral() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetStatistiqueGeneral());
  }, [dispatch]);
  const { StatistiqueGeneral } = useSelector((state) => state.Statistique);
  const Data = [
    {
      titre: "ships",
      icon: <DirectionsBoatIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.ships || 0,

      soustext: "View list",
      linkSousText: "",
    },
    {
      titre: "Customers",
      icon: <GroupsIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.Customers || 0,
      textButton: "+ New Customers ",
      LinkButton: "/commercial/AddCustomer",
      soustext: "View list",
      linkSousText: "/commercial/CustomerManagement",
    },
    {
      titre: "booking",
      icon: <CheckCircleIcon fontSize="large" color="primary" />,
      Number: StatistiqueGeneral.booking || 0,
      soustext: "View list",
      linkSousText: "/commercial/BookingsManagements",
    },
    {
      titre: "captain",
      icon: <img src={CaptainIcon} alt="captin icon" />,
      Number: StatistiqueGeneral.captain || 0,
      soustext: "View list",
      linkSousText: "",
    },
  ];

  return (
    <Grid container spacing={2}>
      {Data.map((d) => (
        <Grid item md={3} xs={6}>
          {d?.textButton ? (
            <CardDataButton data={d} />
          ) : (
            <CardDataSimple data={d} />
          )}
        </Grid>
      ))}
    </Grid>
  );
}

export default NumberGeneral;
