import { CircularProgress, Backdrop } from "@mui/material";
import { useSelector } from "react-redux";

const LoadingPage = () => {
  const loadingBooking = useSelector((state) => state.BookingSlice.loading);
  const loadingVoyage = useSelector((state) => state.VoyageSlice.loading);
  const loadingPorts = useSelector((state) => state.PortsSlice.loading);
  const loadingAuth = useSelector((state) => state.AuthSlice.loading);
  const loadingCategoryPackage = useSelector(
    (state) => state.CategoryPackageSlice.loading
  );
  const loadingProduct = useSelector((state) => state.ProductSlice.loading);
  const loadingStatistique = useSelector((state) => state.Statistique.loading);

  return (
    <Backdrop
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        flexDirection: "column",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 9999,
      }}
      open={
        loadingPorts ||
        loadingVoyage ||
        loadingBooking ||
        loadingAuth ||
        loadingCategoryPackage ||
        loadingProduct ||
        loadingStatistique
      }
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default LoadingPage;
