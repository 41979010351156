import { Box, Dialog, Grid, Select, TextField, MenuItem } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import TitreGraphik from "../Typography/TitreGraphik";
import ButtonSonRadius from "../Button/buttonSonRadius";
import { useDispatch, useSelector } from "react-redux";
import {
  statusPending,
  statusChecked,
  statusRejected,
  statusInProgress,
} from "../../assets/style/status";
import { useState } from "react";
import { EditBookingAction } from "../../store/slice/bookingSlice";
import { serveurUrl } from "../../utils/Api";
import {handleDownload} from "../Booking/ListPackageAndProduct";

export default function EditBooking({ open, onClose, data }) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.Statistique);
  const [style, setStyle] = useState({});
  console.log(data);
  async function changeColor(id) {
    const findStatus = await status.find((e) => e.id === id);
    console.log(findStatus.etat);
    switch (findStatus?.etat) {
      case "in-progress":
        console.log("test1");
        setStyle(statusInProgress);
        break;
      case "checked":
        console.log("test2");
        setStyle(statusChecked);
        break;
      case "pending":
        setStyle(statusPending);
        break;
      case "rejected":
        setStyle(statusRejected);
        break;
      default:
        break;
    }
  }

  function sendForm(e) {
    console.log("test");
    e.preventDefault();
    const DataForm = new FormData(e.target);
    DataForm.append("id", data.id);
    dispatch(EditBookingAction(DataForm));
    onClose();
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth="lg" maxWidth="md">
      <form onSubmit={sendForm}>
        <Grid container spacing={2} padding="3%">
          <Grid item xs={12}>
            <TitreGraphik>⬅ Edit #1</TitreGraphik>
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Route</LabelTypography>
            <TextField value={data?.voyage?.Route} fullWidth sx={styleInput} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Destination</LabelTypography>
            <TextField
              value={data?.voyage?.Destination}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Customer</LabelTypography>
            <TextField
              value={data?.shipper[0]?.NameCustomer}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>N° Voyage</LabelTypography>
            <TextField value={data?.voyage?.id} fullWidth sx={styleInput} />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Date deparature</LabelTypography>
            <TextField
              value={data?.voyage?.dateDeparture}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <LabelTypography>Date arrival</LabelTypography>
            <TextField
              value={data?.voyage?.dateArrival}
              fullWidth
              sx={styleInput}
            />
          </Grid>
          <Grid item xs={12}>
            <LabelTypography>Statue</LabelTypography>
            <Select
              fullWidth
              defaultValue={data?.status?.id}
              name="status"
              sx={{
                ...style, // Apply existing styles
                color: style?.color,
                "& .MuiSelect-select:focus": {
                  borderColor: style?.color, // Set border color when focused
                },
              }}
              onChange={(e) => {
                changeColor(e.target.value);
              }}
            >
              {status?.map((s) => (
                <MenuItem value={s.id}>{s.etat}</MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12}>
            <LabelTypography>Payment</LabelTypography>
            <Select defaultValue={data?.Payé} name="paye" fullWidth>
              <MenuItem value="no">No</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ display: "flex" }}>
              <ButtonSonRadius
                variant="contained"
                type="submit"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                  marginRight: "10px",
                }}
              >
                Save
              </ButtonSonRadius>
              <ButtonSonRadius
                variant="contained"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                  backgroundColor: "rgba(6, 137, 145, 0.1)", // 10% transparency
                  color: "#068991",
                  "&:hover": {
                    backgroundColor: "#068991",
                    color: "white", // Solid color on hover
                  }, // Adjust the color as per your theme
                  marginRight: "10px",
                }}
                onClick={() => {
                  handleDownload(`${serveurUrl}/api/package/${data.Urlimage}`);
                }}
              >
                Download
              </ButtonSonRadius>
              <ButtonSonRadius
                variant="contained"
                type="button"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                  backgroundColor: "rgba(198, 0, 0, 0.1)", // 10%
                  color: "#C60000",
                  "&:hover": {
                    backgroundColor: "#C60000",
                    color: "white", // Solid color on hover
                  },
                  marginRight: "10px",
                }}
              >
                Invoice
              </ButtonSonRadius>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}
