import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import ExportButton from "../../components/Button/ButtonExport";
import ListBookingVoyageCosutmer from "../../components/Booking/ListBookingVoyageCosutmer";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect, useState } from "react";
import {
  GetBooingVoyageCustomerScrole,
  etatInisialBookingSlice,
} from "../../store/slice/bookingSlice";
import EditBooking from "../../components/Popup/EditBooking";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import CustomAlert from "../../components/Alert/Alert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ButtonSimple from "../../components/Button/ButtonSimple";
const head = [
  "N° booking",
  "Creation date",
  "Route",
  "Destination",
  "Customer",
  "N° Voyage",
  "date deparature",
  "date arrival",
  "Status",
  "Invoice amount",
  "Action",
];

export default function PageBookingManagements() {
  return <Indexdasboard Children={<BookingsManagements />} />;
}

function BookingsManagements() {
  const dispatch = useDispatch();
  const { InfobookingDataScrole, bookingDataScrole, etat, message } =
    useSelector((state) => state.BookingSlice);

  const [Page, setPage] = useState(1);
  const [PopupEdit, setPopupEdit] = useState(false);
  const [DataPopup, setDataPopup] = useState();
  const HasMore =
    InfobookingDataScrole.current_page < InfobookingDataScrole.last_page;
  useEffect(() => {
    dispatch(GetBooingVoyageCustomerScrole(Page));
  }, [dispatch, Page]);

  useEffect(() => {
    dispatch(getStatus());
  }, [dispatch]);

  function editPopup(id, data) {
    setDataPopup(data);
    setPopupEdit(true);
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Booking Management</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%", display: { xs: "none", md: "flex" } }}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <TitreGraphik sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                  List of quotes and reservations
                </TitreGraphik>
              </Grid>
              <Grid item xs={2}>
                <ExportButton />
              </Grid>
              <Grid item xs={2}>
                <ButtonSimple
                  variant="contained"
                  disabled
                  startIcon={<CalendarTodayIcon />}
                >
                  This month
                </ButtonSimple>
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={bookingDataScrole.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<h4>Loading...</h4>}
                >
                  <ListBookingVoyageCosutmer
                    head={head}
                    Bookings={bookingDataScrole}
                    edit={editPopup}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Grid container spacing={2}>
              <Grid item xs={6} textAlign="center">
                <ExportButton />
              </Grid>
              <Grid item xs={6} textAlign="center">
                <ButtonSimple
                  variant="contained"
                  disabled
                  startIcon={<CalendarTodayIcon />}
                >
                  This month
                </ButtonSimple>
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={bookingDataScrole.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<h4>Loading...</h4>}
                >
                  <ListBookingVoyageCosutmer
                    head={head}
                    Bookings={bookingDataScrole}
                    edit={editPopup}
                  />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <EditBooking
        open={PopupEdit}
        onClose={() => {
          setPopupEdit(false);
        }}
        data={DataPopup}
      />
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
    </Box>
  );
}
