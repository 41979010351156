import { Grid, TextField } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import { MuiTelInput } from "mui-tel-input";
import { useState } from "react";
function FormCustomer({ data }) {
  const [tel, setTel] = useState(data?.NumberPhone ? data.NumberPhone : "+212");
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <LabelTypography required={true}>Name</LabelTypography>
        <TextField
          type="text"
          placeholder="name"
          name="name"
          fullWidth
          required
          defaultValue={data?.NameCustomer}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LabelTypography>Company</LabelTypography>
        <TextField
          type="text"
          placeholder="Company"
          name="Company"
          fullWidth
          required
          defaultValue={data?.company?.nomCompany}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <LabelTypography required={true}>Adresse</LabelTypography>
        <TextField
          type="text"
          placeholder="Adresse"
          name="Adresse"
          fullWidth
          required
          defaultValue={data?.adress}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelTypography required={true}>Email</LabelTypography>
        <TextField
          type="text"
          placeholder="Email"
          name="Email"
          fullWidth
          required
          defaultValue={data?.Email}
        />
      </Grid>
      <Grid item xs={12}>
        <LabelTypography required={true}>Phone numbe</LabelTypography>
        <MuiTelInput
          fullWidth
          defaultCountry="ma"
          value={tel}
          onChange={(value) => setTel(value)}
          name="number"
        />
      </Grid>
    </Grid>
  );
}

export default FormCustomer;
