import { Box, Typography, Avatar } from "@mui/material";
import LabelTypography from "../Typography/LabelTypography";
import PortImage from "../../assets/Images/port.svg";
import useSortPorts from "../../hooks/SortPorts";
import { useDispatch, useSelector } from "react-redux";
import { ActivePortsAction } from "../../store/slice/PortsSlice";

const calculateDaysBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);
  const differenceInTime = end - start;
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  return Math.round(differenceInDays);
};

const PortVoyage = () => {
  const { ports } = useSelector((state) => state.PortsSlice);
  const dispatch = useDispatch();
  const Portsort = useSortPorts();
  const DataPortSort = Portsort(ports);
  const firstArrivalDate = DataPortSort[0]?.pivot?.dateArrival;
  const lastArrivalDate =
    DataPortSort[DataPortSort.length - 1]?.pivot?.dateArrival;
  const numberOfDays = calculateDaysBetweenDates(
    firstArrivalDate,
    lastArrivalDate
  );

  function editePortActive(id) {
    dispatch(ActivePortsAction(id));
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* Icon and Duration */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
        }}
      >
        <img src={PortImage} alt="port" />
        {!isNaN(numberOfDays) && (
          <Typography variant="h4" component="span" sx={{ fontWeight: "bold" }}>
            {numberOfDays} days
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mt: 2,
          width: "100%",
          textAlign: "center",
        }}
      >
        {" "}
        {DataPortSort.map((p, index) => (
          <>
            {Number(p.pivot.active) === 1 ? (
              <PortActive index={index} name={p.nomPort} />
            ) : (
              <PortSimple
                index={index}
                id={p.id}
                name={p.nomPort}
                editePortActive={editePortActive}
              />
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

const PortActive = ({ index, name }) => {
  return (
    <>
      {index !== 0 && (
        <Box
          sx={{ flex: 1, height: 4, bgcolor: "primary.main", borderRadius: 2 }}
        />
      )}

      <Box sx={{ textAlign: "center" }}>
        <Avatar
          sx={{
            bgcolor: "primary.main",
            color: "primary.main",
            margin: "0 auto",
          }}
        />
        <LabelTypography
          variant="body1"
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            textAlign: "center",
          }}
        >
          {name}
        </LabelTypography>
      </Box>
    </>
  );
};

const PortSimple = ({ index, name, id, editePortActive }) => {
  return (
    <>
      {index !== 0 && (
        <Box
          sx={{ flex: 1, height: 4, bgcolor: "primary.main", borderRadius: 2 }}
        />
      )}

      {/* Port X - End */}
      <Box sx={{ textAlign: "center" }}>
        <Avatar
          sx={{
            bgcolor: "white",
            border: "2px solid",
            borderColor: "primary.main",
            margin: "0 auto", // Texte centré ici
          }}
        />
        <LabelTypography
          variant="body1"
          sx={{ color: "primary.main", textAlign: "center" }}
        >
          {name}
        </LabelTypography>
      </Box>
    </>
  );
};

export default PortVoyage;
