import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Typography } from "@mui/material";
import ButtonSimple from "./ButtonSimple";

const ExportButtonStyle = {
  backgroundColor: "#e8f5e9",
  color: "#43a047",
  "&:hover": {
    backgroundColor: "#d7e9da",
  },
};

const ExportButton = () => {
  return (
    <ButtonSimple
      sx={ExportButtonStyle}
      startIcon={<FileDownloadIcon />}
      variant="contained"
    >
      <Typography
        variant="button"
        sx={{ fontFamily: "Poppins", textTransform: "capitalize" }}
      >
        Export
      </Typography>
    </ButtonSimple>
  );
};

export default ExportButton;
