import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api } from "../../utils/Api";

export const GetProduct = createAsyncThunk("GetProduct", async (params) => {
  const response = await Api.get(`product/getProductbyParams?name=${params}`);
  return response.data;
});

const initialState = {
  ProductsStore: [],
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
};
export const productSlice = createSlice({
  name: "product slice",
  initialState,
  reducers: {
    etatInisialProductSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetProduct.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetProduct.fulfilled, (state, action) => {
      state.ProductsStore = action.payload;
      state.loading = false;
      console.log(action.payload);
    });
    builder.addCase(GetProduct.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default productSlice.reducer;
