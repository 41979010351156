import PageAddCustomer from "../pages/all/AddCustomer";
import PageBookingManagements from "../pages/all/BookingManagements";
import PageCustomerManagement from "../pages/all/CustomerManagement";
import PageManifesteDeBord from "../pages/all/ManifesteDeBord";
import PageNewFastBookingcapitan from "../pages/all/NewFastBooking";
import PageDashboardCaptain from "../pages/captain/DashbordCaptain";
import DashboardCommercial from "../pages/commercial/DashboradCommercial";
import PageVoyageManagementCommercial from "../pages/commercial/VoyageManagementCommercial";
import PageDashboardDock from "../pages/dock/DashbordDock";

const PriviteRoute = [
  // captain

  {
    path: "/captain/dashboard",
    element: <PageDashboardCaptain />,
    role: "captain",
  },
  {
    path: "/captain/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "captain",
  },
  {
    path: "/captain/ManifesteDeBord",
    element: <PageManifesteDeBord />,
    role: "captain",
  },

  // dock

  {
    path: "/dock/dashboard",
    element: <PageDashboardDock />,
    role: "dock",
  },
  {
    path: "/dock/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "dock",
  },

  // commercial

  {
    path: "/commercial/dashboard",
    element: <DashboardCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/NewBooking",
    element: <PageNewFastBookingcapitan />,
    role: "commercial",
  },
  {
    path: "/commercial/dashboard",
    element: <DashboardCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/VoyageManagement",
    element: <PageVoyageManagementCommercial />,
    role: "commercial",
  },
  {
    path: "/commercial/CustomerManagement",
    element: <PageCustomerManagement />,
    role: "commercial",
  },
  {
    path: "/commercial/AddCustomer",
    element: <PageAddCustomer />,
    role: "commercial",
  },
  {
    path: "/commercial/BookingsManagements",
    element: <PageBookingManagements />,
    role: "commercial",
  },
];

export default PriviteRoute;
