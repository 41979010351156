import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import { Link } from "react-router-dom";
import useSortPorts from "../../hooks/SortPorts";
import DateFormate from "../date/dateFormat";
import StatusLabel from "../status/status";
import ButtonRadius from "../Button/ButtonRadius";

function VoyageList({ voyages, head }) {
  const Portsort = useSortPorts();
  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            {head?.map((h) => (
              <StyledTableCell align="center">{h}</StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {voyages?.map((voyage) => {
            const DataPortSort = Portsort(voyage.ports);
            return (
              <StyledTableRow>
                <StyledTableCell align="center">
                  <Link>{voyage.id}</Link>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {voyage.ships.nameShips}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {voyage.captain.name}
                </StyledTableCell>
                {head.includes("Route") && (
                  <StyledTableCell align="center">test</StyledTableCell>
                )}
                {head.includes("Departure Port") && (
                  <StyledTableCell align="center">
                    {DataPortSort[0].nomPort}
                  </StyledTableCell>
                )}
                {head.includes("Layovers") && (
                  <StyledTableCell align="center">
                    {DataPortSort.slice(1, DataPortSort.length - 1).map(
                      (port, index) => (
                        <p key={index + 2}>{port.nomPort}</p>
                      )
                    )}
                  </StyledTableCell>
                )}
                {head.includes("Arival Port") && (
                  <StyledTableCell align="center">
                    {DataPortSort[DataPortSort.length - 1].nomPort}
                  </StyledTableCell>
                )}

                {head.includes("Date and time cutof") && (
                  <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
                    <DateFormate date={voyage.dateCutof} />
                  </StyledTableCell>
                )}
                {head.includes("Date & time departure") && (
                  <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
                    <DateFormate date={voyage.dateDeparture} />
                  </StyledTableCell>
                )}
                {head.includes("Date and time Arrival") && (
                  <StyledTableCell align="center" sx={{ minWidth: "150px" }}>
                    <DateFormate date={voyage.dateArrival} />
                  </StyledTableCell>
                )}

                {head.includes("Status") && (
                  <StyledTableCell align="center">
                    <StatusLabel status={voyage.status.etat} />
                  </StyledTableCell>
                )}
                {head.includes("Action") && (
                  <StyledTableCell align="center">
                    <ButtonRadius
                      sx={{ background: "#064C91", color: "white" }}
                    >
                      manifeste
                    </ButtonRadius>
                  </StyledTableCell>
                )}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default VoyageList;
