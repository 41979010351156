import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api, Api_Token } from "../../utils/Api";

export const NewBookingAction = createAsyncThunk(
  "new fast booking action",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await Api_Token.post("/booking/NewBooking", data);
      return response;
    } catch (error) {
      console.log(error);
      // Return an object with an 'error' property containing the error message
      return rejectWithValue({ error: error.message });
    }
  }
);

export const updateOnBoardAction = createAsyncThunk(
  "update OnBoard Action ",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await Api_Token.post("/booking/EditOnboard", data);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue({ error: error.message });
    }
  }
);

export const updatelivredAction = createAsyncThunk(
  "update livre Action ",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await Api_Token.post("/booking/editlivre", data);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue({ error: error.message });
    }
  }
);

export const updatePayedAction = createAsyncThunk(
  "update paye Action ",
  async (data, { rejectWithValue }) => {
    try {
      console.log(data);
      const response = await Api_Token.post("/booking/editPaye", data);
      return response;
    } catch (error) {
      console.log(error);
      return rejectWithValue({ error: error.message });
    }
  }
);

export const BookingByVoyage = createAsyncThunk(
  "get bookin voyage captain",
  async (param) => {
    const response = await Api_Token.get(
      `voyage/getVoyageWithBookingsAndCustomers?filter=${param}`
    );
    return response.data;
  }
);

export const BookingSonVoyage = createAsyncThunk(
  "get booking son voyage",
  async (data) => {
    let apiUrl = "booking/getBookingSonVoyage?page=" + data.page;

    // Check if params exist and add filter to the API URL
    if (data.params) {
      apiUrl += "&filter=" + data.params;
    }

    const response = await Api_Token.get(apiUrl);
    return response.data;
  }
);
export const GetBookingData = createAsyncThunk(
  "Get the package and more booking information",
  async (id) => {
    const response = await Api_Token.get(
      `booking/getBookingPackageAndProduits/${id}`
    );
    return response.data;
  }
);

export const GetStokeType = createAsyncThunk("type stoke ", async () => {
  const response = await Api_Token.get(`TypeStoke/getStoke`);
  return response.data;
});

export const Getstoke = createAsyncThunk(
  "get stoke number package",
  async () => {
    const response = await Api.get("/stoke/getStoke");
    return response.data;
  }
);

export const AddStokeInAction = createAsyncThunk(
  "add stoke in ",
  async (data, { rejectWithValue }) => {
    try {
      const response = await Api_Token.post(`stoke/NewStokeIn`, data);
      return response.data;
    } catch (error) {
      console.log(error);
      return rejectWithValue({ error: error.message });
    }
  }
);

export const GetBooingVoyageCustomer = createAsyncThunk(
  "get booking voyage customer",
  async (data) => {
    const response = await Api_Token.get(
      `/booking/getBooingVoyageCustomer?order=${data.order}&number=${data.number}`
    );
    return response.data;
  }
);

export const GetBooingVoyageCustomerScrole = createAsyncThunk(
  "get booking voyage customer scrole",
  async (page) => {
    const response = await Api_Token.get(
      `/booking/getBooingVoyageCustomer?page=${page}`
    );
    return response.data;
  }
);

export const EditBookingAction = createAsyncThunk(
  "edit booking",
  async (data) => {
    const response = await Api_Token.post(`/booking/edit`, data);
    return response.data;
  }
);

export const ManifesteBookingAction = createAsyncThunk(
  "edit MANIFESTE booking",
  async (data) => {
    const response = await Api_Token.post(`/booking/manifeste`, data);
    return response.data;
  }
);

const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  bookingVoyage: {},
  bookingSonVoyage: [],
  bookings: [],
  bookingData: {},
  InfobookingDataScrole: {},
  bookingDataScrole: [],
  TypeStoke: [],
  Number: null,
  PopupNumber: false,
  stokeIn: [],
};
export const BookingSlice = createSlice({
  name: "booking slice",
  initialState,
  reducers: {
    etatInisialBookingSlice: (state, action) => {
      state.etat = "inisial";
    },
    popupNumberClose: (state, action) => {
      state.PopupNumber = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(NewBookingAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(NewBookingAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = "Booking successful";
      state.Number = action.payload.data.number;
      console.log(action.payload);
      state.PopupNumber = true;
    });
    builder.addCase(NewBookingAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.payload
        ? action.payload.error
        : "Failed to add booking";
    });

    builder.addCase(BookingByVoyage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(BookingByVoyage.fulfilled, (state, action) => {
      state.loading = false;
      state.bookingVoyage = action.payload;
    });
    builder.addCase(BookingByVoyage.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(updateOnBoardAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateOnBoardAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = "Booking update successful";
    });
    builder.addCase(updateOnBoardAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.payload
        ? action.payload.error
        : "Failed to update booking";
    });

    builder.addCase(updatelivredAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updatelivredAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = "Booking update successful";
    });
    builder.addCase(updatelivredAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.payload
        ? action.payload.error
        : "Failed to update booking";
    });

    builder.addCase(updatePayedAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updatePayedAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = "Booking update successful";
    });
    builder.addCase(updatePayedAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.payload
        ? action.payload.error
        : "Failed to update booking";
    });
    builder.addCase(GetBookingData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetBookingData.fulfilled, (state, action) => {
      state.loading = false;
      state.bookingData = action.payload;
    });
    builder.addCase(GetBookingData.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetStokeType.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStokeType.fulfilled, (state, action) => {
      state.loading = false;
      state.TypeStoke = action.payload;
    });
    builder.addCase(GetStokeType.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(Getstoke.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(Getstoke.fulfilled, (state, action) => {
      state.stokeIn = action.payload;
      state.loading = false;
    });
    builder.addCase(Getstoke.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(AddStokeInAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(AddStokeInAction.fulfilled, (state, action) => {
      state.loading = false;
      state.stokeIn = action.payload.type;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(AddStokeInAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = "add storage rejected";
    });
    builder.addCase(BookingSonVoyage.pending, (state, action) => {
      if (state.bookingSonVoyage.length < 0) {
        state.loading = true;
      }
    });
    builder.addCase(BookingSonVoyage.fulfilled, (state, action) => {
      state.loading = false;
      state.InfobookingDataScrole = action.payload;
      if (action.payload.current_page === 1) {
        state.bookingSonVoyage = action.payload.data;
      } else {
        state.bookingSonVoyage = [
          ...state.bookingSonVoyage,
          ...action.payload.data,
        ];
      }
    });
    builder.addCase(BookingSonVoyage.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetBooingVoyageCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetBooingVoyageCustomer.fulfilled, (state, action) => {
      state.bookings = action.payload.data;
      state.loading = false;
    });
    builder.addCase(GetBooingVoyageCustomer.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetBooingVoyageCustomerScrole.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      GetBooingVoyageCustomerScrole.fulfilled,
      (state, action) => {
        state.InfobookingDataScrole = action.payload;
        const newBookings = action.payload.data.filter(
          (newBooking) =>
            !state.bookingDataScrole.some(
              (existingBooking) => existingBooking.id === newBooking.id
            )
        );

        state.bookingDataScrole = [...state.bookingDataScrole, ...newBookings];
        state.loading = false;
      }
    );
    builder.addCase(GetBooingVoyageCustomerScrole.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(EditBookingAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditBookingAction.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.etat = "success";
      state.loading = false;
    });
    builder.addCase(EditBookingAction.rejected, (state, action) => {
      state.message = action.payload.message;
      state.etat = "error";
      state.loading = "false";
    });
    builder.addCase(ManifesteBookingAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(ManifesteBookingAction.fulfilled, (state, action) => {
      state.message = action.payload.message;
      state.etat = "success";
      state.loading = false;
    });
    builder.addCase(ManifesteBookingAction.rejected, (state, action) => {
      state.message = action.payload.message;
      state.etat = "error";
      state.loading = "false";
    });
  },
});

export default BookingSlice.reducer;
export const { etatInisialBookingSlice, popupNumberClose } =
  BookingSlice.actions;
