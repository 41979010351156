import React, { useEffect } from "react";
import {
  Grid,
  Box,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableContainer,
  IconButton,
} from "@mui/material";
import { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import TitreGraphik from "../Typography/TitreGraphik";
import ButtonSonRadius from "../Button/buttonSonRadius";
import UploadFile from "./Uploadfile";
import ProductPackage, { ProductPackageResponsive } from "./ProductPackage";
import AddProduitsPopup from "../Popup/AddProduitsPopup";
import LabelTypography from "../Typography/LabelTypography";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

export const StyleCell = {
  minWidht: "50px",
  fontWeight: "bold",
};
function Package({
  pack,
  index,
  packages,
  AddProductsPackage,
  AddfilePackage,
  errorFiles,
  UpdateQuantite,
  handleRemove,
  UpdateStoke,
  setOpenAddStoke,
}) {
  const [open, setOpen] = useState(false);
  const [Prix, setPrix] = useState(0);
  const { stokeIn } = useSelector((state) => state.BookingSlice);

  useEffect(() => {
    const calculePrix = () => {
      let totalPrice = 0;
      pack.produits.forEach((produit) => {
        console.log(produit.product.prixProduct);
        console.log(produit.quantity);
        totalPrice += produit.product.prixProduct * produit.quantity;
      });
      setPrix(totalPrice);
    };

    calculePrix();
  }, [packages, pack.produits]);

  return (
    <>
      <hr
        style={{
          border: "none",
          height: "1px",
          backgroundColor: "gray",

          marginTop: "4%",
          marginBottom: "3%",
        }}
      />
      <Box width="100%" paddingLeft="3%">
        <Grid container spacing={4}>
          <Grid item xs={6} alignItems="flex-start">
            <TitreGraphik>Package {index + 1}</TitreGraphik>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              height: "100%", // Pour que le bouton prenne toute la hauteur du Grid item
            }}
          >
            <ButtonSonRadius
              variant="contained"
              onClick={() => setOpen(true)}
              sx={{
                marginRight: "2%",
                backgroundColor: "#064C91",
                height: "8vh",
                "&:hover": {
                  backgroundColor: "#0A3D6A",
                },
              }}
            >
              +Add Product
            </ButtonSonRadius>
          </Grid>
          <Grid xs={12} padding="10px">
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <Box width="100%">
                <TableContainer marginLeft="2%" marginRight="2%">
                  <Table>
                    <TableHead sx={{ background: "#F8F8F8" }}>
                      <TableCell align="center" sx={StyleCell}>
                        Name
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Category
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Quantité
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Unité Price
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}>
                        Price Total
                      </TableCell>
                      <TableCell align="center" sx={StyleCell}></TableCell>
                    </TableHead>
                    <TableBody>
                      {pack.produits.map((product, indexProduit) => (
                        <ProductPackage
                          index={index}
                          indexProduit={indexProduit}
                          product={product}
                          handleRemove={() => {
                            handleRemove(index, indexProduit);
                          }}
                          UpdateQuantite={(value) => {
                            UpdateQuantite(index, indexProduit, value);
                          }}
                          UpdateStoke={(value) => {
                            UpdateStoke(index, indexProduit, value);
                          }}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Box>
                <Grid container spacing={2}>
                  {pack.produits.map((product, indexProduit) => (
                    <Grid item xs={12}>
                      <ProductPackageResponsive
                        index={index}
                        indexProduit={indexProduit}
                        product={product}
                        handleRemove={() => {
                          handleRemove(index, indexProduit);
                        }}
                        UpdateQuantite={(value) => {
                          UpdateQuantite(index, indexProduit, value);
                        }}
                        UpdateStoke={(value) => {
                          UpdateStoke(index, indexProduit, value);
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <UploadFile
              setFile={(file) => AddfilePackage(index, file)}
              errorFile={errorFiles.includes(index)}
              file={pack.file}
            />
          </Grid>
          <Grid item xs={12} md={3} order={{ xs: 1, md: 2 }}>
            <Box padding="2%" marginTop="4%" sx={{ background: "white" }}>
              <Grid container spacing={2} marginTop="1%">
                <Grid item xs={9}>
                  <LabelTypography required={true}>storage</LabelTypography>
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => {
                      setOpenAddStoke(true);
                    }}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={pack.stoke || null}
                      onChange={(e) => {
                        UpdateStoke(index, e.target.value);
                      }}
                      required
                      sx={{ height: "8vh" }}
                    >
                      {stokeIn.map((s) => (
                        <MenuItem
                          value={s.id}
                        >{`${s.type__stoke.type} - N ${s.id} `}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} order={{ xs: 3, md: 3 }}>
            <Box padding="2%" marginTop="4%" sx={{ background: "white" }}>
              <Grid container spacing={2} marginTop="1%">
                <Grid item xs={6} md={12}>
                  <LabelTypography>price package :</LabelTypography>
                </Grid>
                <Grid item xs={6} md={12}>
                  <LabelTypography>{Prix} vuv</LabelTypography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <AddProduitsPopup
        open={open}
        onclose={() => {
          setOpen(false);
        }}
        AddProducts={(products) => {
          AddProductsPackage(index, products);
        }}
      />
    </>
  );
}

export default Package;
