import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const GetVoyageDashboard = createAsyncThunk(
  "get voyage list dashboard",
  async (data) => {
    const response = await Api_Token.get(
      `voyage/getVoyageAndCaptainAndPorts?${
        data.sort ? `sortDir=${data.sort}&` : ""
      }${data.number ? `Number=${data.number}&` : ""}${
        data.statusId ? `statusId=${data.statusId}` : ""
      }`.replace(/&$/, "")
    );
    return response.data;
  }
);

export const GetVoyageManagemnt = createAsyncThunk(
  "get voyage list management",
  async (data) => {
    const response = await Api_Token.get(
      `voyage/getVoyageAndCaptainAndPorts?${
        data.sort ? `sortDir=${data.sort}&` : ""
      }${data.number ? `Number=${data.number}&` : ""}${
        data.statusId ? `statusId=${data.statusId}` : ""
      }${data.page ? `page=${data.page}&` : ""}`.replace(/&$/, "")
    );
    return response.data;
  }
);

export const GetVoyageManagemntFilter = createAsyncThunk(
  "get voyage list management filter",
  async (data) => {
    const response = await Api_Token.get(
      `voyage/getVoyageAndCaptainAndPorts?${
        data.sort ? `sortDir=${data.sort}&` : ""
      }${data.number ? `Number=${data.number}&` : ""}${
        data.statusId ? `statusId=${data.statusId}` : ""
      }${data.page ? `page=${data.page}&` : ""}`.replace(/&$/, "")
    );
    return response.data;
  }
);

const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  voyageDashboard: [],
  voyageManagemnet: [],
  infoScroole: {},
};
export const VoyagesSlice = createSlice({
  name: "Voyage slice",
  initialState,
  reducers: {
    etatInisialBookingSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetVoyageDashboard.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageDashboard.fulfilled, (state, action) => {
      state.loading = false;
      state.voyageDashboard = action.payload.data;
    });
    builder.addCase(GetVoyageDashboard.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetVoyageManagemnt.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageManagemnt.fulfilled, (state, action) => {
      state.loading = false;
      state.infoScroole = action.payload;
      const newVoyages = action.payload.data.filter(
        (newVoyage) =>
          !state.voyageManagemnet.some(
            (existingVoyage) => existingVoyage.id === newVoyage.id
          )
      );
      state.voyageManagemnet = [...state.voyageManagemnet, ...newVoyages];
    });
    builder.addCase(GetVoyageManagemnt.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(GetVoyageManagemntFilter.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetVoyageManagemntFilter.fulfilled, (state, action) => {
      state.loading = false;
      state.infoScroole = action.payload;
      state.voyageManagemnet = action.payload.data;
    });
    builder.addCase(GetVoyageManagemntFilter.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default VoyagesSlice.reducer;
