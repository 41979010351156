import { Button, Typography } from "@mui/material";

function ButtonSonRadius({ children, ...props }) {
  return (
    <Button
      sx={{
        borderRadius: 0,
      }}
      {...props}
    >
      <Typography
        variant="button"
        sx={{ fontFamily: "Poppins", textTransform: "capitalize" }}
      >
        {children}
      </Typography>
    </Button>
  );
}
export default ButtonSonRadius;
