import { Box, Card, Grid } from "@mui/material";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import FormCustomer from "../../components/customer/FormCustomer";
import ButtonSonRadius from "../../components/Button/buttonSonRadius";
import { useDispatch, useSelector } from "react-redux";
import { SendCustomer } from "../../store/slice/customerSlice";
import { useNavigate } from "react-router-dom";

export default function PageAddCustomer() {
  return <Indexdasboard Children={<AddCustomer />} />;
}

function AddCustomer() {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const { user } = useSelector((state) => state.AuthSlice);
  function sendForm(e) {
    e.preventDefault();
    const Data = new FormData(e.target);
    dispatch(SendCustomer(Data));
    Navigate(`/${user.Role}/CustomerManagement`);
  }
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>⬅ New customer</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={sendForm}>
            <Card sx={{ padding: "3%" }}>
              <FormCustomer />
              <ButtonSonRadius
                variant="contained"
                type="submit"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                }}
              >
                Save
              </ButtonSonRadius>
            </Card>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}
