import LabelTypography from "../Typography/LabelTypography";
import editIcon from "../../assets/Images/editIcon.svg";
import { Box, Button, Divider, Grid, Card, CardContent } from "@mui/material";

export default function ActionBookinStatus({
  setPopupOnboard,
  setId,
  setPaye,
  setlivre,
  b,
}) {
  const getColor = (status) => {
    switch (status) {
      case "yes":
        return "green";
      case "no":
        return "red";
      default:
        return "orange";
    }
  };
  const Stylebutton = {
    backgroundColor: "#064C91",
    "&:hover": {
      backgroundColor: "#064C91", // Same color on hover
    },
    "&:active": {
      backgroundColor: "#064C91", // Same color on click
    },
    "&:focus": {
      backgroundColor: "#064C91", // Same color on focus
    },
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F5F5",
          padding: "4%",
        }}
      >
        <LabelTypography color="text.primary">
          On board :
          <LabelTypography component="span" sx={{ color: getColor(b.OnBoard) }}>
            {" "}
            {b.OnBoard}
          </LabelTypography>
        </LabelTypography>

        <Button
          variant="contained"
          sx={Stylebutton}
          size="small"
          onClick={() => {
            setId(b.id);
            setPopupOnboard(true);
          }}
          startIcon={
            <img
              src={editIcon}
              alt="Edit"
              height="20" // Adjust height as needed
              style={{ marginRight: "5px" }}
            />
          }
        >
          Edit
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F5F5",
          padding: "4%",
        }}
      >
        <LabelTypography color="text.primary">
          Livré :
          <LabelTypography component="span" sx={{ color: getColor(b.Payé) }}>
            {" "}
            {b.Payé}
          </LabelTypography>
        </LabelTypography>

        <Button
          variant="contained"
          sx={Stylebutton}
          size="small"
          onClick={() => {
            setId(b.id);
            setPaye(true);
          }}
          startIcon={
            <img
              src={editIcon}
              alt="Edit"
              height="20" // Adjust height as needed
              style={{ marginRight: "5px" }}
            />
          }
        >
          Edit
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          background: "#F5F5F5",
          padding: "4%",
        }}
      >
        <LabelTypography color="text.primary">
          Payé :
          <LabelTypography component="span" sx={{ color: getColor(b.Livré) }}>
            {" "}
            {b.Livré}
          </LabelTypography>
        </LabelTypography>

        <Button
          variant="contained"
          sx={Stylebutton}
          size="small"
          onClick={() => {
            setId(b.id);
            setlivre(true);
          }}
          startIcon={
            <img
              src={editIcon}
              alt="Edit"
              height="20" // Adjust height as needed
              style={{ marginRight: "5px" }}
            />
          }
        >
          Edit
        </Button>
      </Box>
    </>
  );
}
