import { Box, Card, Grid } from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LabelTypography from "../../components/Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";
import { useEffect, useState } from "react";
import ButtonSimple from "../../components/Button/ButtonSimple";
import { useDispatch, useSelector } from "react-redux";
import { getStatus } from "../../store/slice/StatistiqueSlice";
import VoyageList from "./VoyageList";
import {
  GetVoyageManagemnt,
  GetVoyageManagemntFilter,
} from "../../store/slice/VoyageSlice";
import InfiniteScroll from "react-infinite-scroll-component";

function VoyageManagement({ NewVoyage, head }) {
  const dispatch = useDispatch();
  const { status } = useSelector((state) => state.Statistique);
  const { voyageManagemnet, infoScroole } = useSelector(
    (state) => state.VoyageSlice
  );
  const HasMore = infoScroole.current_page < infoScroole.last_page;
  const [Page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [etat, setEtat] = useState("");
  useEffect(() => {
    if (status.length === 0) {
      dispatch(getStatus());
    }
  }, [dispatch, status]);

  useEffect(() => {
    const data = {
      sort: order,
      statusId: etat,
      page: Page,
    };
    dispatch(GetVoyageManagemnt(data));
  }, [dispatch, Page]);

  useEffect(() => {
    const data = {
      sort: order,
      statusId: etat,
    };
    dispatch(GetVoyageManagemntFilter(data));
  }, [dispatch, order, etat]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>Voyage management</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Card sx={{ padding: "1%" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {NewVoyage && (
                  <ButtonSimple variant="contained">
                    + Create a new voyage
                  </ButtonSimple>
                )}
              </Grid>
              <Grid item xs={6} md={3}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <LabelTypography id="sortList">sort</LabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sortlist"
                          id="sortList"
                          name="sortList"
                          sx={styleInput}
                          value={order}
                          onChange={(e) => setOrder(e.target.value)}
                        >
                          <MenuItem value={"desc"}>
                            From newest to oldest
                          </MenuItem>
                          <MenuItem value={"asc"}>
                            From oldest to newest
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} md={3}>
                <Box>
                  <Grid container>
                    <Grid
                      item
                      xs={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                      }}
                    >
                      <LabelTypography id="sortList">Filter</LabelTypography>
                    </Grid>
                    <Grid item xs={9}>
                      <FormControl fullWidth>
                        <Select
                          labelId="sortlist"
                          id="sortList"
                          name="sortList"
                          sx={styleInput}
                          value={etat}
                          onChange={(e) => setEtat(e.target.value)}
                        >
                          {status.map((s) => (
                            <MenuItem value={s.id}>{s.etat}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <InfiniteScroll
                  dataLength={voyageManagemnet.length}
                  next={() => {
                    setPage(Page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<h4>Loading...</h4>}
                >
                  <VoyageList voyages={voyageManagemnet} head={head} />
                </InfiniteScroll>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default VoyageManagement;
