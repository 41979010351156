import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const getStatus = createAsyncThunk("get etat", async () => {
  const response = await Api_Token.get(`status/getStatus`);
  return response.data;
});
export const GetStatistiqueGeneral = createAsyncThunk(
  "StatistiqueGeneral",
  async () => {
    const response = await Api_Token.get(`statistique/statistiqueGeneral`);
    return response.data;
  }
);
const initialState = {
  loading: false,
  etat: "inisial", // inisial  , success  , error
  message: "",
  StatistiqueGeneral: {},
  status: [],
};
export const StatistiqueSlice = createSlice({
  name: "Statistique slice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetStatistiqueGeneral.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetStatistiqueGeneral.fulfilled, (state, action) => {
      state.StatistiqueGeneral = action.payload;
      state.loading = false;
    });
    builder.addCase(GetStatistiqueGeneral.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getStatus.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getStatus.fulfilled, (state, action) => {
      state.loading = false;
      state.status = action.payload;
    });
    builder.addCase(getStatus.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export default StatistiqueSlice.reducer;
