import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Api_Token } from "../../utils/Api";

export const GetCustomer = createAsyncThunk("get customer", async (page) => {
  const response = await Api_Token.get(`customer/getAll?page=${page}`);
  return response.data;
});

export const SendCustomer = createAsyncThunk("add customer", async (data) => {
  const response = await Api_Token.post(`customer/AddCustomer`, data);
  return response.data;
});

export const EditCustomerAction = createAsyncThunk(
  "edit customer",
  async (data) => {
    const response = await Api_Token.post(`customer/editCustomer`, data);
    return response.data;
  }
);

const initialState = {
  loading: false,
  CustomerData: {},
  CustomerAllData: [],
  etat: "inisial", // inisial  , success  , error
  message: "",
};

export const CustomerSlice = createSlice({
  name: "CustomerSlice",
  initialState,
  reducers: {
    etatInisialCustomerSlice: (state, action) => {
      state.etat = "inisial";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(GetCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(GetCustomer.fulfilled, (state, action) => {
      state.loading = false;
      const newCustomers = action.payload.data.filter(
        (newCustomer) =>
          !state.CustomerAllData.some(
            (existingCustomer) => existingCustomer.id === newCustomer.id
          )
      );
      state.CustomerData = action.payload;
      state.CustomerAllData = [...state.CustomerAllData, ...newCustomers];
    });
    builder.addCase(GetCustomer.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(SendCustomer.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(SendCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(SendCustomer.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.error;
    });

    builder.addCase(EditCustomerAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(EditCustomerAction.fulfilled, (state, action) => {
      state.loading = false;
      state.etat = "success";
      state.message = action.payload.message;
    });
    builder.addCase(EditCustomerAction.rejected, (state, action) => {
      state.loading = false;
      state.etat = "error";
      state.message = action.error;
    });
  },
});

export default CustomerSlice.reducer;

export const { etatInisialCustomerSlice } = CustomerSlice.actions;
