import ListPackageAndProduct from "../../components/Booking/ListPackageAndProduct";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetBookingData } from "../../store/slice/bookingSlice";
import LoadingPage from "../../components/loading";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import { Box } from "@mui/material";

const DataBookingPage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { loading, bookingData } = useSelector((state) => state.BookingSlice);
  useEffect(() => {
    if (id) {
      dispatch(GetBookingData(id)); 
    }
  }, [id, dispatch]);
  console.log(bookingData);
  return (
    <Box>
      <Indexdasboard
        Children={<ListPackageAndProduct bookingData={bookingData} id={id} />}
      />
      <LoadingPage open={loading} />
    </Box>
  );
};

export default DataBookingPage;
