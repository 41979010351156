import * as React from "react";
import Paper from "@mui/material/Paper";
import TitreGraphik from "../../components/Typography/TitreGraphik";

import { useDispatch, useSelector } from "react-redux";
import {
  BookingSonVoyage,
  updateOnBoardAction,
} from "../../store/slice/bookingSlice";
import LoadingPage from "../../components/loading";
import UpdateStatusBooking from "../../components/Popup/updateStatusBooking";
import CustomAlert from "../../components/Alert/Alert";
import { etatInisialBookingSlice } from "../../store/slice/bookingSlice";
import { styleInput } from "../../assets/style/Input";
import { TextField, InputAdornment, Grid, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import ButtonTransparent from "../../components/Button/ButtonTransparent";
import ButtonSimple from "../../components/Button/ButtonSimple";
import ListBooking from "../../components/Booking/ListBooking";
import { Link } from "react-router-dom";
import NumberBooking from "../../components/Popup/NumberBooking";

const head = [
  "N° booking",
  "Shipper",
  "Receiver",
  "Destination",
  "Nb Colis",
  "On board ",
];
const PageDashboardDock = () => {
  return <Indexdasboard Children={<DashboardDock />} />;
};
export default PageDashboardDock;

export function DashboardDock() {
  const dispatch = useDispatch();
  const { bookingSonVoyage } = useSelector((state) => state.BookingSlice);
  const { etat, message } = useSelector((state) => state.BookingSlice);
  const [popupOnboard, setPopupOnboard] = React.useState(false);
  const [filter, setFilter] = React.useState("");
  const [id, setId] = React.useState();
  function GetData() {
    dispatch(BookingSonVoyage(""));
  }
  React.useEffect(() => {
    const data = {
      params: filter,
      page: 1,
    };
    dispatch(BookingSonVoyage(data));
  }, [dispatch, filter]);

  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
      >
        <Grid item>
          <TitreGraphik>Dashboard</TitreGraphik>
        </Grid>
        <Grid item>
          <Box display="flex" gap="10px">
            <Link to="/dock/NewBooking">
              <ButtonTransparent>+ New booking</ButtonTransparent>
            </Link>
            <ButtonSimple variant="contained">Manifeste de bord</ButtonSimple>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "3%",
        }}
      ></Box>
      <Paper sx={{ padding: "2%" }}>
        <TitreGraphik>Booking list</TitreGraphik>
        <br />
        <br />
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          placeholder="Chercher par par ID booking, shipper ou receiver ..."
          sx={{ ...styleInput, minWidth: "35%" }}
        />
        <br />
        <br />
        <ListBooking
          booking={bookingSonVoyage}
          setId={setId}
          setPopupOnboard={setPopupOnboard}
          head={head}
          role="dock"
        />
      </Paper>
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
      <LoadingPage />
      <UpdateStatusBooking
        open={popupOnboard}
        onclose={() => {
          setPopupOnboard(false);
        }}
        title="update on board"
        onsend={(data) => {
          dispatch(updateOnBoardAction(data)).then((result) => {
            if (updateOnBoardAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPopupOnboard(false);
        }}
        id={id}
      />
      <NumberBooking />
    </Box>
  );
}
