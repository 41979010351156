import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
} from "@mui/material";
import ButtonSonRadius from "../Button/buttonSonRadius";
import TitreGraphik from "../Typography/TitreGraphik";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

export default function UpdateStatusBooking({
  open,
  onclose,
  title,
  onsend,
  id,
}) {
  function SendData(e) {
    e.preventDefault();
    const data = {
      id: id,
      data: e.target.data.value,
    };
    onsend(data);
  }
  return (
    <Dialog open={open}>
      <form onSubmit={SendData}>
        <DialogTitle sx={{ textAlign: "center" }}>
          <TitreGraphik>{title}</TitreGraphik>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{title}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label={title}
              name="data"
            >
              <MenuItem value="yes">yes</MenuItem>
              {title === "update on board" && (
                <MenuItem value="to be checked">to be checked</MenuItem>
              )}
              <MenuItem value="in progress">in progress</MenuItem>

              <MenuItem value="no">no</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <ButtonSonRadius variant="contained" color="error" onClick={onclose}>
            cancel
          </ButtonSonRadius>
          <ButtonSonRadius
            variant="contained"
            sx={{
              backgroundColor: "#064C91",

              "&:hover": {
                backgroundColor: "#0A3D6A", // Change background color on hover
              },
            }}
            type="submit"
          >
            Add
          </ButtonSonRadius>
        </DialogActions>
      </form>
    </Dialog>
  );
}
