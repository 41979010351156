import { Box, Grid } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ButtonSonRadius from "../Button/buttonSonRadius";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { useEffect, useRef, useState } from "react";
import LabelTypography from "../Typography/LabelTypography";

export default function UploadFile({ setFile, errorFile, file }) {
  const [ImageSrc, setImageSrc] = useState();
  const fileInputRef = useRef(null);
  const fileInputTakeRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleButtontakeClick = () => {
    fileInputTakeRef.current.click();
  };

  useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };

      reader.readAsDataURL(file);
    }
  }, [file]);
  return (
    <Box padding="2%" marginTop="1%" sx={{ background: "white" }}>
      <Grid container spacing={2} marginTop="1%">
        <Grid item xs={12}>
          <LabelTypography required={true}>delivery Docket</LabelTypography>
        </Grid>
        <Grid item xs={6}>
          <ButtonSonRadius
            onClick={handleButtonClick}
            startIcon={<FileUploadIcon />}
            variant="contained"
            sx={{
              backgroundColor: "#064C91",
              height: "8vh",
              "&:hover": {
                backgroundColor: "#0A3D6A", // Change background color on hover
              },
            }}
          >
            upload an image
          </ButtonSonRadius>
        </Grid>
        <Grid item xs={6}>
          <ButtonSonRadius
            onClick={handleButtontakeClick}
            startIcon={<PhotoCameraIcon />}
            variant="outlined"
            sx={{
              color: "#064C91",
              height: "8vh",
            }}
          >
            Take a photo
          </ButtonSonRadius>
        </Grid>
        <Grid item xs={12}>
          {errorFile && (
            <div style={{ color: "red" }}>Please select a file</div>
          )}
        </Grid>
        <Grid item xs={12}>
          {file && (
            <img src={ImageSrc} alt="File" height="100px" width="auto" />
          )}
        </Grid>
      </Grid>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }} // Masquer l'input
        accept="image/*"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
      <input
        type="file"
        ref={fileInputTakeRef}
        accept="image/*"
        capture="environment" // For capturing photos from the device's camera
        style={{ display: "none" }}
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
    </Box>
  );
}
