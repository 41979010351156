import { Grid, TextField, Box } from "@mui/material";
import TitreGraphik from "../Typography/TitreGraphik";
import LabelTypography from "../Typography/LabelTypography";
import { styleInput } from "../../assets/style/Input";

export default function NewFastBookingFormCostumer({ errors }) {
  return (
    <Box>
      <Grid container spacing={4} padding="5px">
        <Grid item xs={12}>
          <TitreGraphik>Shipper informations</TitreGraphik>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>Shipper Name</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="ShipperName"
            aria-label="ShipperName"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>
            Shipper Phone Number
          </LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            error={errors.ShipperPhoneNumber ? true : false}
            helperText={errors.ShipperPhoneNumber}
            name="ShipperPhoneNumber"
            aria-label="ShipperPhoneNumber"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>Shipper E-mail</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            error={errors.ShipperEmail ? true : false}
            helperText={errors.ShipperEmail}
            name="ShipperEmail"
            aria-label="ShipperEmail"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TitreGraphik>Receiver informations</TitreGraphik>
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>Receiver Name</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            name="ReceiverName"
            aria-label="ReceiverName"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>
            Receiver Phone Number
          </LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            error={errors.ReceiverPhoneNumber ? true : false}
            helperText={errors.ReceiverPhoneNumber}
            name="ReceiverPhoneNumber"
            aria-label="ReceiverPhoneNumber"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <LabelTypography required={true}>Receiver E-mail</LabelTypography>
          <TextField
            type="text"
            fullWidth
            required
            error={errors.ReceiverEmail ? true : false}
            helperText={errors.ReceiverEmail}
            name="ReceiverEmail"
            aria-label="ReceiverEmail"
            InputProps={{
              style: styleInput,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
