import {
  Box,
  Card,
  Grid,
  TextField,
  InputAdornment,
  Checkbox,
  Typography,
  CardContent,
} from "@mui/material";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { useEffect, useState } from "react";
import { styleInput } from "../../assets/style/Input";
import SearchIcon from "@mui/icons-material/Search";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { StyledTableCell, StyledTableRow } from "../../assets/style/Table";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingSonVoyage,
  ManifesteBookingAction,
} from "../../store/slice/bookingSlice";
import ButtonSimple from "../../components/Button/ButtonSimple";
import { etatInisialBookingSlice } from "../../store/slice/bookingSlice";
import CustomAlert from "../../components/Alert/Alert";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingPage from "../../components/loading";

export default function PageManifesteDeBord() {
  return <Indexdasboard Children={<ManifesteDeBord />} />;
}

function ManifesteDeBord() {
  const [Filter, setFilter] = useState();
  const [selected, setSelected] = useState([]);
  const [page, setpage] = useState(1);
  const { bookingSonVoyage, etat, message, InfobookingDataScrole } =
    useSelector((state) => state.BookingSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      params: Filter,
      page: page,
    };
    dispatch(BookingSonVoyage(data));
  }, [Filter, dispatch, page]);
  const HasMore =
    InfobookingDataScrole.current_page < InfobookingDataScrole.last_page;

  /*  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };*/

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  function Send() {
    console.log(selected);
    const data = {
      Booking: selected,
    };
    dispatch(ManifesteBookingAction(data)).then((result) => {
      if (ManifesteBookingAction.fulfilled.match(result)) {
        setFilter();
        setpage(1);
      }
    });
  }
  const isSelected = (id) => selected.indexOf(id) !== -1;
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitreGraphik>⬅ Manifeste De Bord</TitreGraphik>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Box width="100%">
              <Card sx={{ padding: "2%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        value={Filter}
                        onChange={(e) => {
                          setFilter(e.target.value);
                          setpage(1);
                        }}
                        placeholder="Chercher par par ID booking, shipper ou receiver ..."
                        sx={{ ...styleInput, minWidth: "35%" }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <InfiniteScroll
                      dataLength={bookingSonVoyage.length}
                      next={() => {
                        setpage(page + 1);
                      }}
                      hasMore={HasMore}
                      loader={<h4>Loading...</h4>}
                    >
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <StyledTableRow>
                              <StyledTableCell padding="checkbox"></StyledTableCell>
                              <StyledTableCell>Id Booking</StyledTableCell>
                              <StyledTableCell>Shipper</StyledTableCell>
                              <StyledTableCell>Receiver</StyledTableCell>
                              <StyledTableCell>Destination</StyledTableCell>
                              <StyledTableCell>Nb colis</StyledTableCell>
                              <StyledTableCell>Value</StyledTableCell>
                            </StyledTableRow>
                          </TableHead>
                          <TableBody>
                            {bookingSonVoyage?.map((b, index) => {
                              const isItemSelected = isSelected(b.id);
                              const labelId = `enhanced-table-checkbox-${index}`;
                              const shipper = b.customer.find(
                                (c) => c.pivot.type === "Shipper"
                              );
                              // Find the customer of type 'Receiver'
                              const receiver = b.customer.find(
                                (c) => c.pivot.type === "Receiver"
                              );

                              const destination = b.port.find(
                                (p) => p.pivot.typePort === "destination"
                              );
                              return (
                                <StyledTableRow
                                  hover
                                  onClick={(event) => handleClick(event, b.id)}
                                  role="checkbox"
                                  aria-checked={isItemSelected}
                                  tabIndex={-1}
                                  key={b.id}
                                  selected={isItemSelected}
                                >
                                  <StyledTableCell padding="checkbox">
                                    <Checkbox
                                      checked={isItemSelected}
                                      inputProps={{
                                        "aria-labelledby": labelId,
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    component="th"
                                    id={labelId}
                                    scope="row"
                                  >
                                    <Link href="#">{b.id}</Link>
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {shipper.NameCustomer}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {receiver.NameCustomer}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {destination.nomPort}
                                  </StyledTableCell>
                                  <StyledTableCell>
                                    {b.QtyPackage}
                                  </StyledTableCell>

                                  <StyledTableCell>{b.price}</StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </InfiniteScroll>
                  </Grid>
                </Grid>
              </Card>
              <ButtonSimple
                variant="contained"
                onClick={Send}
                sx={{ width: "40%", marginTop: "3%" }}
              >
                Confirme
              </ButtonSimple>
            </Box>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <Box width="100%">
              <Box>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  value={Filter}
                  onChange={(e) => {
                    setFilter(e.target.value);
                    setpage(1);
                  }}
                  placeholder="Chercher par par ID booking, shipper ou receiver ..."
                  sx={{ ...styleInput, minWidth: "35%" }}
                  fullWidth
                />
              </Box>
              <Box>
              <InfiniteScroll
                  dataLength={bookingSonVoyage.length}
                  next={() => {
                    setpage(page + 1);
                  }}
                  hasMore={HasMore}
                  loader={<h4>Loading...</h4>}
                >
              <Grid
                container
                spacing={2}
                sx={{ marginTop: "10px", marginBottom: "15vh" }}
              >
              
            
                  {bookingSonVoyage.map((b) => {
                    const isItemSelected = isSelected(b.id);
                    const shipper = b.customer.find(
                      (c) => c.pivot.type === "Shipper"
                    );
                    // Find the customer of type 'Receiver'
                    const receiver = b.customer.find(
                      (c) => c.pivot.type === "Receiver"
                    );

                    const destination = b.port.find(
                      (p) => p.pivot.typePort === "destination"
                    );
                    return (
                      <Grid item xs={12} key={b.id}>
                        <Card selected={isItemSelected}>
                          <CardContent>
                            <Grid container>
                              <Grid item xs={2}>
                                <Checkbox
                                  checked={isItemSelected}
                                  onChange={(event) => handleClick(event, b.id)}
                                  inputProps={{ "aria-labelledby": b.id }}
                                />
                              </Grid>
                              <Grid item xs={10}>
                                <Typography>
                                  ID Booking: <Link href="#">{b.id}</Link>
                                </Typography>
                                <Typography>
                                  Destination:{" "}
                                  <strong> {destination.nomPort}</strong>
                                </Typography>
                                <Typography>
                                  From: <strong> {shipper.NameCustomer}</strong>
                                </Typography>
                                <Typography>
                                  To: <strong> {receiver.NameCustomer}</strong>
                                </Typography>
                                <Typography>
                                  Number of Packages:{" "}
                                  <strong> {b.QtyPackage}</strong>
                                </Typography>

                                <Typography>
                                  Value: <strong>{b.price}</strong>
                                </Typography>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
              
              </Grid>
                </InfiniteScroll>
              </Box>
              <Box
                height="10vh"
                sx={{
                  position: "fixed",
                  bottom: 0,
                  background: "white",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <ButtonSimple
                  variant="contained"
                  onClick={Send}
                  sx={{ width: "80%" }}
                >
                  Confirme
                </ButtonSimple>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
      <LoadingPage />
    </Box>
  );
}
