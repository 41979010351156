import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  DialogTitle,
  TableRow,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TitreGraphik from "../Typography/TitreGraphik";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetProduct } from "../../store/slice/ProductSlice";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import { StyleCell } from "../Package/Package";
import ButtonSonRadius from "../Button/buttonSonRadius";

export default function AddProduitsPopup({ open, onclose, AddProducts }) {
  const [addedProducts, setAddedProducts] = useState([
    { quantity: "", product: null },
  ]);

  // filter les product not null et plus que  0
  const sendProduct = () => {
    const filteredProducts = addedProducts.filter(
      (product) => product.product && product.quantity > 0
    );
    AddProducts(filteredProducts);
    setAddedProducts([{ quantity: "", product: null }]);
    onclose();
  };

  // add chamb de product
  const NewProduct = (product) => {
    setAddedProducts([...addedProducts, { quantity: "", product: null }]);
  };

  // update product par index
  function UpdateProduit(index, prod) {
    console.log(prod);
    setAddedProducts((prev) => {
      const updatedProducts = [...prev];

      updatedProducts[index] = {
        ...updatedProducts[index],
        product: prod,
      };
      return updatedProducts;
    });
  }

  // update quantite par index
  function UpdateQunatite(index, quantity) {
    setAddedProducts((prev) => {
      const updatedProducts = [...prev];

      updatedProducts[index] = {
        ...updatedProducts[index],
        quantity: parseInt(quantity, 0),
      };
      return updatedProducts;
    });
  }
  // supp chamb
  const handleRemove = (index) => {
    const updatedProducts = [...addedProducts];
    updatedProducts.splice(index, 1);
    setAddedProducts(updatedProducts);
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TitreGraphik>Add products</TitreGraphik>
          <IconButton color="error" onClick={onclose}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <IconButton color="error" onClick={onclose}>
                <CancelIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 2, // margin-top to add space between the cancel button and the title
              }}
            >
              <TitreGraphik>Add products</TitreGraphik>
              <IconButton color="primary" onClick={NewProduct}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <Table>
            <TableHead>
              <TableCell sx={StyleCell} colSpan={3}>
                product{" "}
              </TableCell>
              <TableCell sx={StyleCell}>quantity</TableCell>
              <TableCell sx={StyleCell}>
                <IconButton color="primary" onClick={NewProduct}>
                  <AddCircleOutlineIcon />
                </IconButton>
              </TableCell>
            </TableHead>
            <TableBody>
              {addedProducts.map((product, index) => (
                <RowProductADD
                  index={index}
                  handleRemove={handleRemove}
                  product={product}
                  UpdateQunatite={UpdateQunatite}
                  UpdateProduit={UpdateProduit}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ display: { xs: "flex", md: "none" } }}>
          <Box>
            <Box>
              {addedProducts.map((product, index) => (
                <RowProductADDResponsive
                  index={index}
                  handleRemove={handleRemove}
                  product={product}
                  UpdateQunatite={UpdateQunatite}
                  UpdateProduit={UpdateProduit}
                  NewProduct={NewProduct}
                />
              ))}
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <ButtonSonRadius variant="contained" color="error" onClick={onclose}>
          cancel
        </ButtonSonRadius>
        <ButtonSonRadius
          variant="contained"
          sx={{
            backgroundColor: "#064C91",

            "&:hover": {
              backgroundColor: "#0A3D6A", // Change background color on hover
            },
          }}
          onClick={sendProduct}
        >
          Add
        </ButtonSonRadius>
      </DialogActions>
    </Dialog>
  );
}

function RowProductADD({
  index,
  handleRemove,
  product,
  UpdateQunatite,
  UpdateProduit,
}) {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProduct(search));
  }, [dispatch, search]);
  const { ProductsStore } = useSelector((state) => state.ProductSlice);
  const { loading } = useSelector((state) => state.ProductSlice);

  return (
    <TableRow>
      <TableCell colSpan={3}>
        <Autocomplete
          disablePortal
          id="ProductsStore"
          options={ProductsStore || []}
          getOptionLabel={(option) =>
            `${option.nameCategory} - ${option.nameProduct}`
          }
          sx={{ width: 300 }}
          loading={loading} // Indicateur de chargement basé sur la disponibilité de ProductsStore
          loadingText="Loading..." // Texte à afficher pendant le chargement
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search by product or category"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={!ProductsStore}
            />
          )}
          value={product?.product}
          onChange={(event, value) => UpdateProduit(index, value)}
        />
      </TableCell>
      <TableCell>
        <TextField
          type="number"
          label="quantite"
          value={product?.quantity}
          onChange={(e) => UpdateQunatite(index, e.target.value)}
        />
      </TableCell>
      <TableCell>
        <IconButton
          color="error"
          onClick={() => {
            handleRemove(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

function RowProductADDResponsive({
  index,
  handleRemove,
  product,
  UpdateQunatite,
  UpdateProduit,
}) {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetProduct(search));
  }, [dispatch, search]);
  const { ProductsStore } = useSelector((state) => state.ProductSlice);
  const { loading } = useSelector((state) => state.ProductSlice);

  return (
    <Box sx={{ p: 2, border: "1px solid #e0e0e0", borderRadius: 2, mb: 2 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="ProductsStore"
            options={ProductsStore || []}
            getOptionLabel={(option) =>
              `${option.nameCategory} - ${option.nameProduct}`
            }
            loading={loading}
            loadingText="Loading..."
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search by product or category"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                disabled={!ProductsStore}
              />
            )}
            value={product?.product}
            onChange={(event, value) => UpdateProduit(index, value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            type="number"
            label="Quantity"
            fullWidth
            value={product?.quantity}
            onChange={(e) => UpdateQunatite(index, e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <IconButton
            color="error"
            onClick={() => handleRemove(index)}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DeleteIcon />
            <Typography variant="button" sx={{ ml: 1 }}>
              Delete
            </Typography>
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}
