import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import HomeIcon from "@mui/icons-material/Home";

import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useDispatch, useSelector } from "react-redux";
import { MenuToolPhoneFalse } from "../../store/slice/StyleSlice";
import { Link, useLocation } from "react-router-dom";
import LabelTypography from "../Typography/LabelTypography";

const MenuContainer = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: "0 0 2px 0 rgba(0, 0, 0, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.12)",
  backgroundColor: "#fff",
  display: "flex",
  maxWidth: 263,
  flexDirection: "column",
  fontSize: 8,
  color: "#666",
  fontWeight: 300,
  padding: "24px 16px 64px",
  minHeight: "80vh",
  position: "sticky",
  top: "150px",
  [theme.breakpoints.down("sm")]: {
    fontSize: 6,
    padding: "16px 8px 32px",
  },
}));

const menuItemsByRole = {
  captain: [
    { icon: <HomeIcon />, label: "dashboard", path: "/captain/dashboard" },
  ],

  dock: [{ icon: <HomeIcon />, label: "dashboard", path: "/dock/dashboard" }],

  commercial: [
    { icon: <HomeIcon />, label: "dashboard", path: "/commercial/dashboard" },
    {
      icon: <HomeIcon />,
      label: "Voyage management",
      path: "/commercial/VoyageManagement",
    },
    {
      icon: <HomeIcon />,
      label: "Customer Management",
      path: "/commercial/CustomerManagement",
    },
    {
      icon: <HomeIcon />,
      label: "Booking management",
      path: "/commercial/BookingsManagements",
    },
  ],
};

const getMenuItemsByRole = (role) => {
  return menuItemsByRole[role] || [];
};
function MenuDashboard() {
  const { Role } = useSelector((state) => state.AuthSlice.user);
  const menuItems = getMenuItemsByRole(Role);
  console.log(menuItems);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { MenuPhone } = useSelector((state) => state.style);
  const location = useLocation();

  const menuList = (
    <>
      {menuItems?.map((item) => {
        const isActive = location.pathname === item.path;
        return (
          <ListItem key={item.label} disablePadding>
            <Link
              to={item.path}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ListItemButton
                sx={{
                  color: isActive ? "primary.main" : "inherit",
                  fontWeight: isActive ? "bold" : "normal",
                }}
              >
                <ListItemIcon
                  sx={{ color: isActive ? "primary.main" : "inherit" }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={<LabelTypography>{item.label}</LabelTypography>}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        );
      })}
    </>
  );

  return (
    <div>
      {isMobile ? (
        <>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              dispatch(MenuToolPhoneFalse());
            }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={MenuPhone}
            onClick={() => {
              dispatch(MenuToolPhoneFalse());
            }}
          >
            {menuList}
          </Drawer>
        </>
      ) : (
        <MenuContainer>{menuList}</MenuContainer>
      )}
    </div>
  );
}

export default MenuDashboard;
