import { Card, Dialog, Grid } from "@mui/material";
import FormCustomer from "../customer/FormCustomer";
import ButtonSonRadius from "../Button/buttonSonRadius";
import TitreGraphik from "../Typography/TitreGraphik";
import { useDispatch } from "react-redux";
import { EditCustomerAction } from "../../store/slice/customerSlice";

export default function EditCustomer({ open, onClose, data }) {
  const dispatch = useDispatch();

  function sendForm(e) {
    e.preventDefault();
    const Data = new FormData(e.target);
    Data.append("id", data.id);
    console.log(Data);
    dispatch(EditCustomerAction(Data));
    onClose();
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <Card sx={{ padding: "3%" }}>
        <form onSubmit={sendForm}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TitreGraphik>⬅ Edit customer</TitreGraphik>
            </Grid>
            <Grid item xs={12}>
              <FormCustomer data={data} />
            </Grid>
            <Grid item xs={12}>
              <ButtonSonRadius
                variant="contained"
                type="submit"
                sx={{
                  height: "10vh",
                  marginTop: "3%",
                  width: "30vh",
                }}
              >
                Save
              </ButtonSonRadius>
            </Grid>
          </Grid>
        </form>
      </Card>
    </Dialog>
  );
}
