import { Button, Typography } from "@mui/material";

function ButtonSimple({ children, ...props }) {
  return (
    <Button {...props}>
      <Typography
        variant="button"
        sx={{ fontFamily: "Poppins", textTransform: "capitalize" }}
      >
        {children}
      </Typography>
    </Button>
  );
}

export default ButtonSimple;
