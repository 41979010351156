import * as React from "react";
import Paper from "@mui/material/Paper";
import TitreGraphik from "../../components/Typography/TitreGraphik";
import { styleInput } from "../../assets/style/Input";
import { TextField, InputAdornment, Box, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  BookingByVoyage,
  updateOnBoardAction,
  updatePayedAction,
  updatelivredAction,
} from "../../store/slice/bookingSlice";
import LoadingPage from "../../components/loading";
import UpdateStatusBooking from "../../components/Popup/updateStatusBooking";
import CustomAlert from "../../components/Alert/Alert";
import { etatInisialBookingSlice } from "../../store/slice/bookingSlice";
import PortVoyage from "../../components/port/portVoyage";
import Indexdasboard from "../../components/dashboard/IndexDashbord";
import ButtonTransparent from "../../components/Button/ButtonTransparent";
import ButtonSimple from "../../components/Button/ButtonSimple";
import ListBooking from "../../components/Booking/ListBooking";
import { Link } from "react-router-dom";
import NumberBooking from "../../components/Popup/NumberBooking";
import { getVoyagePortCaptain } from "../../store/slice/PortsSlice";

const head = [
  "N° booking",
  "Shipper",
  "Receiver",
  "Destination",
  "Nb Colis",
  "On board ",
  "Payé",
  "Livré",
];
const PageDashboardCaptain = () => {
  return <Indexdasboard Children={<DashboardCaptain />} />;
};
export default PageDashboardCaptain;

export function DashboardCaptain() {
  const [filter, setFilter] = React.useState("");
  const dispatch = useDispatch();
  const { bookingVoyage } = useSelector((state) => state.BookingSlice);
  const { etat, message } = useSelector((state) => state.BookingSlice);
  const [popupOnboard, setPopupOnboard] = React.useState(false);
  const [popupPaye, setPaye] = React.useState(false);
  const [popupLivre, setlivre] = React.useState(false);
  const [id, setId] = React.useState();
  function GetData() {
    dispatch(BookingByVoyage(filter));
  }
  React.useEffect(() => {
    dispatch(getVoyagePortCaptain());
  }, [dispatch]);
  React.useEffect(() => {
    dispatch(BookingByVoyage(filter));
  }, [filter, dispatch]);
  return (
    <Box>
      <Grid
        container
        alignItems="center"
        justifyContent="space-between"
        padding="10px"
      >
        <Grid item xs={12} md={6}>
          <TitreGraphik>Dashboard</TitreGraphik>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            display="flex"
            justifyContent={{
              xs: "space-evenly", // Espacement égal sur les petits écrans
              md: "flex-end", // Les boutons sont alignés au début avec un petit espace entre eux sur les écrans moyens et plus grands
            }}
            textAlign="center"
            sx={{
              gap: {
                xs: 0, // Pas d'espace entre les boutons sur les petits écrans
                md: "10px", // Espace de 10px entre les boutons sur les écrans moyens et plus grands
              },
              width: "100%",
            }}
          >
            <Link to="/captain/NewBooking">
              <ButtonTransparent>+ New booking</ButtonTransparent>
            </Link>
            <Link to="/captain/ManifesteDeBord">
              <ButtonSimple variant="contained">Manifeste de bord</ButtonSimple>
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "3%",
        }}
      >
        <Box
          sx={{
            width: { xs: "90%", md: "50%" },
          }}
        >
          <PortVoyage />
        </Box>
      </Box>
      <Box sx={{ display: { xs: "none", md: "flex" } }}>
        <Paper sx={{ padding: "2%", width: "100%" }}>
          <TitreGraphik>Booking list</TitreGraphik>
          <br />
          <br />
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            placeholder="Chercher par par ID booking, shipper ou receiver ..."
            sx={{ ...styleInput, minWidth: "35%" }}
          />
          <br />
          <br />
          <ListBooking
            booking={bookingVoyage?.booking}
            setId={setId}
            setPaye={setPaye}
            setPopupOnboard={setPopupOnboard}
            setlivre={setlivre}
            role="captain"
            head={head}
          />
        </Paper>
      </Box>
      <Box sx={{ display: { xs: "flex", md: "none" } }}>
        <Box sx={{ width: "100%", padding: "2%" }}>
          <br />
          <br />
          <TitreGraphik>Booking list</TitreGraphik>
          <br />
          <br />
          <ListBooking
            booking={bookingVoyage?.booking}
            setId={setId}
            setPaye={setPaye}
            setPopupOnboard={setPopupOnboard}
            setlivre={setlivre}
            role="captain"
            head={head}
          />
        </Box>
      </Box>
      <CustomAlert
        open={etat !== "inisial"}
        onClose={() => {
          dispatch(etatInisialBookingSlice());
        }}
        etat={etat}
        message={message}
      />
      <LoadingPage />
      <UpdateStatusBooking
        open={popupOnboard}
        onclose={() => {
          setPopupOnboard(false);
        }}
        title="update on board"
        onsend={(data) => {
          dispatch(updateOnBoardAction(data)).then((result) => {
            if (updateOnBoardAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPopupOnboard(false);
        }}
        id={id}
      />
      <UpdateStatusBooking
        open={popupPaye}
        onclose={() => {
          setPaye(false);
        }}
        title="update Payé"
        onsend={(data) => {
          dispatch(updatePayedAction(data)).then((result) => {
            if (updatePayedAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setPaye(false);
        }}
        id={id}
      />
      <UpdateStatusBooking
        open={popupLivre}
        onclose={() => {
          setlivre(false);
        }}
        title="update Livré"
        onsend={(data) => {
          dispatch(updatelivredAction(data)).then((result) => {
            if (updatelivredAction.fulfilled.match(result)) {
              GetData();
            }
          });
          setlivre(false);
        }}
        id={id}
      />
      <NumberBooking />
    </Box>
  );
}
